import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField   } from '@mui/material';
import {ECalApplicationFormBodyHead,PatientAccordion,FormGridItemGrey,FormGridItem,FamilyAccordion,
        convFloat,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import {useSelector, useDispatch, } from 'react-redux'
import { setState,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import moment from 'moment';
import {useEffectOnce} from '../common/common-lib.js';
import axios from 'axios';
const APIHost = window.APIHost;

function ECalApplicationFormBodyIncome(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;    
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;
    let isDrug = props.ecalcState.isDrug;
    let DeductTotal = props.ecalcState.DeductTotal;
    let DeductPersonalAllowance  = props.ecalcState.DeductPersonalAllowance;
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);
     
        if(allReset){ props.onReset();}else{
          dispatch(setSubTotal2(0));                       
          //GetPersonalAllowance();
          if(allReset){ props.onReset();}else{
            let _householdIncomeAssets = householdIncomeAssets.slice(0,1).map((item) => {return { ...item, 
              salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});        
            
              dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal:0,householdAssetTotal,householdMemberCount:1,
              householdIncomeAssets:_householdIncomeAssets,DeductTotal,DeductPersonalAllowance,
            }));
            dispatch(setDeductPersonalAllowance(0));
  
          }}
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const hHouseholdAssetChg = (ev,id)=>{
      //console.log(ev);
      var MAX_INTEGER = 922337203685477;
      let _householdIncomeAssets = updateHouseholdincomeItem(householdIncomeAssets,id,ev.item,(ev.item==='patient_rel')?ev.val:convFloat(ev.val));
      
      let _subtotal = 
      _householdIncomeAssets[id].salary+
      _householdIncomeAssets[id].allowance+
      _householdIncomeAssets[id].double_pay+
      _householdIncomeAssets[id].benefit_in_kind+
      _householdIncomeAssets[id].pension+
      _householdIncomeAssets[id].rental_income+
      _householdIncomeAssets[id].sponsor+
      _householdIncomeAssets[id].other_income;

      _householdIncomeAssets = updateHouseholdincomeItem(_householdIncomeAssets,id,"subTotal",_subtotal);
      
      householdIncomeTotal=0;
      _householdIncomeAssets.forEach((record)=>{               
        householdIncomeTotal +=record.subTotal;
      });
      if(householdIncomeTotal >= MAX_INTEGER){
       householdIncomeTotal = MAX_INTEGER
      }
      dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
  
   }
   const GetPersonalAllowance= ()=>{
    //input: hhm_size//HouseholdMemberCount
    //output: result//0: Fail; 1:Success
    //assessment_date//2023-11-07
    //hhm_size
    // personal_allowance
    axios({
      method: 'post',
      url: APIHost+'/getPersonalAllowance',
      data: {
        "hhm_size":householdMemberCount,
        "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
      },
     
    }).then((resp)=>{
      //console.log(resp.data);
      dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
              DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
      props.calculateDeduct(()=>{
        setDiagTitle("");
        setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        setOpen(true);   
      });              
    }).catch((err)=>{
      console.log(err);
      setDiagTitle("Connection error");            
      setDiagContent(err.toString());
      setOpen(true);
    })
  }
  useEffectOnce(()=>{  
    GetPersonalAllowance();
  });
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]
    
    //console.log(props.ecalcState)
    
    return(
      <>
      <Box   sx={{ p:1,m:1,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHead  isDrug={isDrug} onChange={props.onChange} stepVal={props.stepVal}  enabledStep={props.enabledStep}/>
      
      <Stack borderBottom={1} sx={{ ml:1 }}>
        <Typography variant="h5" > <br/></Typography>
        <Typography variant="h6"  color="grey.700" > {props.i18n.t("tab2_header")} ${householdIncomeTotal}</Typography>
        <Typography variant="h6"  color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
        
      </Stack> 
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 16,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button>
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 16,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, initial */ 
            setDiagContent(props.i18n.t("msg_confirm_clear_all"));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(true);
            setOpen2(true);
        }}>{props.i18n.t("btnClearAll")}</Button>
      </Stack> 
      <Typography variant="body" paragraph >  {props.i18n.t("tab2_note")} <IconButton onClick={()=>{handleClickOpen("tab2_note_tooltip_title","tab2_note_tooltip_desc")}} ><img src="images/question_mark.png"/></IconButton></Typography>
      <Typography variant="body" > {props.i18n.t("input_integer_only")}</Typography>
      <PatientAccordion keyid={"fa0"} FamilyIdx={0} householdincomeArry={householdIncomeAssets} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
        onChange={(ev)=>{ hHouseholdAssetChg(ev,0) }}  i18n={props.i18n}/>
      <>{
      
      }</>  
      {householdIncomeAssets.map((income)=>{
        //console.log(income);
        if(income.id>0)
          return (<FamilyAccordion key={"fa"+income.id}  relationStr={relationStr}  FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} 
          onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}}
          onRemove={(id)=>{
                            let _householdIncomeAssets = removeHouseholdincomeItem(householdIncomeAssets,id);
                            EstimatedItemCost =0;
                            householdIncomeTotal=0;
                            EstimatedItemCost=0
                            _householdIncomeAssets.forEach((record)=>{                              
                              householdIncomeTotal +=record.subTotal;
                            });
                            householdMemberCount =_householdIncomeAssets.length;
                            dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,})); 
                          }} 
          onChange={(ev)=>{ hHouseholdAssetChg(ev,income.id) }}  
          i18n={props.i18n}/>)
      })}
      

      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="body" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
        <TextField value={"$"+householdIncomeTotal} variant="outlined"  sx={{p:0.5,input: {textAlign: "right",backgroundColor:'grey.200',width:'5.1rem'}}} inputProps={{sx:{py:0.5}}}/>
      </Stack>
  
      <Button  variant="text" startIcon={<img src="/images/blue_cross.png"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{   
          let _householdIncomeAssets = addHouseholdincomeItemExist(householdIncomeAssets, {    
                                                                          patient_rel:'',
                                                                          salary:0,
                                                                          allowance:0,
                                                                          double_pay:0,
                                                                          benefit_in_kind:0,
                                                                          pension:0,
                                                                          rental_income:0,
                                                                          sponsor:0,
                                                                          other_income:0,
                                                                          subTotal:0,
                                                                          cash:0,
                                                                          saving:0,
                                                                          investment:0,
                                                                          insurance:0,
                                                                          property:0,
                                                                          carpark:0,
                                                                          land:0,
                                                                          other:0,
                                                                          subTotal2:0,
                                                                        });
            householdMemberCount =_householdIncomeAssets.length;
            dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
          
      }} > {props.i18n.t("tab2_btnAddMember")} </Button>  
  
      <Grid container spacing={2} >
        <Grid item xs={4} >
          <Button variant="outlined" onClick={()=>{props.onChange("info")}}  ><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} ></Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("asset");props.onNext();}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalApplicationFormBodyIncome}