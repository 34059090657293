import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField } from '@mui/material';
import {ECalApplicationFormBodyHeadDesktop,PatientAccordionDesktop,PatientAccordionHeaderDesktop,FamilyAccordionDesktop,SubtotalIncomeAccordionDesktop,
        convFloat, FormGridItemDesktop,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import { useDispatch, useSelector} from 'react-redux'
import { setState,setSubTotal2,  setDeductGrandTotal,
  setSubTotal1,
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import axios from 'axios';
import {useEffectOnce} from '../common/common-lib.js';
import moment from 'moment';
const APIHost = window.APIHost;


function ECalcApplicationFormMonthlyDeduct(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    let isDrug = props.ecalcState.isDrug;

    const DeductRentMortgage  =useSelector((state)=>state.ecalc.DeductRentMortgage??0);
    const DeductRatesGovRent  =useSelector((state)=>state.ecalc.DeductRatesGovRent??0);
    const DeductMgtFee  =useSelector((state)=>state.ecalc.DeductMgtFee??0);
    
    const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax??0);
    const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF??0);
    const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp??0);
    const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee??0);
    const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp??0);
    const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid??0);
    const DeductPersonalAllowance =useSelector((state)=>state.ecalc.DeductPersonalAllowance??0);

    const SubTotal1 =useSelector((state)=>state.ecalc.SubTotal1);
    const SubTotal2 =useSelector((state)=>state.ecalc.SubTotal2??0);
    const DeductGrandTotal =useSelector((state)=>state.ecalc.DeductGrandTotal??0);
    const DeductTotal =useSelector((state)=>state.ecalc.DeductTotal);

    

    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);
    
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;

    const lang = useSelector((state)=> state.config.lang);
    //  const [mDeductRentMortgage,setmDeductRentMortgage] =React.useState(DeductRentMortgage);
    //  const [mmDeductRatesGovRent,setmDeductRatesGovRent] =React.useState(DeductRatesGovRent);
    //  const [mDeductMgtFee,setmDeductMgtFee] =React.useState(setDeductMgtFee);



    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);
        
        if(allReset){ props.onReset();}else{
          let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item,
             };});    
          dispatch(setState({isDrug,_CCFMD,recurrentApp,householdIncomeTotal,householdAssetTotal,householdMemberCount,
            householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance,EstimatedItemCost,
          }));
          dispatch(setDeductRentMortgage(0));
          dispatch(setDeductRatesGovRent(0));
          dispatch(setDeductMgtFee(0));
          dispatch(setDeductSalariesTax(0));
          dispatch(setDeductMPF(0));
          dispatch(setDeductChildCareExp(0));
          dispatch(setDeductSchoolFee(0));
          dispatch(setDeductAvgMedicalExp(0));
          dispatch(setDeductMaintPaid(0));
          
        }
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]   
    
    const GetPersonalAllowance= ()=>{
      //input: hhm_size//HouseholdMemberCount
      //output: result//0: Fail; 1:Success
      //assessment_date//2023-11-07
      //hhm_size
      // personal_allowance
      axios({
        method: 'post',
        url: APIHost+'/getPersonalAllowance',
        data: {
          "hhm_size":householdMemberCount,
          "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
        },
       
      }).then((resp)=>{
        // console.log(resp.data);
        dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
                DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
        // props.calculateDeduct(()=>{
        //   setDiagTitle("");
        //   setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        //   setOpen(true);   
        // });                
      }).catch((err)=>{
        console.log(err);
        setDiagTitle("Connection error");            
        setDiagContent(err.toString());
        setOpen(true);
      })
    }

    
    useEffectOnce(()=>{
      GetPersonalAllowance();
    });
    React.useEffect(()=>{
      props.calculateDeduct(()=>{
        setDiagTitle("");
        setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        setOpen(true);   
      });
    },[DeductRentMortgage,DeductRatesGovRent,DeductMgtFee,DeductSalariesTax,
      DeductMPF,
      DeductChildCareExp,
      DeductSchoolFee,
      DeductAvgMedicalExp,
      DeductMaintPaid,
      DeductPersonalAllowance]);

      const [Summary_PatientAmount,setSummary_PatientAmount] = React.useState(0);
      const [lbl_drug_adfr_times_cont,setlbl_drug_adfr_times_cont] = React.useState(false);
      const [lbl_drug_no_item_cost,setlbl_drug_no_item_cost] = React.useState(false);
      const [Summary_SubsidyAmount,setSummary_SubsidyAmount] = React.useState(0);
      const [DrugSummary_LumpSumDeduct,setDrugSummary_LumpSumDeduct]=React.useState(0);
      const [ADFR_Result,setADFR_Result] = React.useState(0);
      const [ADFR_ContRatio_Result,setADFR_ContRatio_Result]=React.useState("");
      const [ADFR_LumpSumDeduct,setADFR_LumpSumDeduct]=React.useState(0);
      const [ADFR_IncomeFactor,setADFR_IncomeFactor] = React.useState(80);
      const [trSFFixAmt,setTrSFFixAmt]=React.useState(false);
      const [FixAmt_Result,setFixAmt_Result]=React.useState("");
      const [ContRatio_Result,setContRatio_Result]=React.useState("");
      
      const [msg_income_catg,setMsg_income_catg]=React.useState("");
      const [lbl_tab5_nondrug_asset_result,setlbl_tab5_nondrug_asset_result]=React.useState("");
      const [lbl_tab5_nondrug_asset_allowance,setlbl_tab5_nondrug_asset_allowance]=React.useState("");
      const [NonDrugSummary_ContRatio,setNonDrugSummary_ContRatio]=React.useState(0);
      
      
    return(
      <>
      <Box   sx={{ p:2,m:2,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHeadDesktop isDrug={isDrug} onChange={(newVal)=>{props.onChange(newVal);}} ecalcState={props.ecalcState} stepVal={props.stepVal}  enabledStep={props.enabledStep} i18n={props.i18n}/>
      
      <Typography variant="h6" paragraph> </Typography>
      <Grid container >
        <Grid item xs={4} alignContent="flex-end">        
            <Typography variant="h5" sx={{pt:1}} color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
        </Grid>    
        <Grid item xs={3}  >
           
        </Grid>  
        <Grid item xs={1} >
        </Grid>    
        <Grid item xs={4} >
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
            <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{                                             
                          props.printPreviewPre({});                          
                  }} >  {props.i18n.t("btnPreview")}</Button>
          
            <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
                /*confirm clear popup, initial */ 
                setDiagContent(props.i18n.t("msg_confirm_clear_all"));
                setDiagTitle(props.i18n.t("tab2_header"));
                setAllReset(true);
                setOpen2(true);
            }}>{props.i18n.t("btnClearAll")}</Button>
          </Stack> 
        </Grid>    
      </Grid>
      <Typography variant="h6" paragraph > </Typography>      
      <Typography variant="h6"  color="grey.700">  {props.i18n.t("tab4_note")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_note_tooltip_title","tab4_note_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
             
      <Typography variant="h6" paragraph > </Typography>

      <Typography variant="h6"  color="grey.700">  {props.i18n.t("tab4_deduct_sum")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_deduct_sum_tooltip_title","tab4_deduct_sum_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
        
      <Grid container spacing={0} >
        <Grid item xs={6} >
            <Typography variant="h6"  color="grey.700" sx={{px:'3.5rem',pt:'1rem'}}>  {props.i18n.t("tab4_rent")} </Typography>
        </Grid>
        <Grid item xs={6} >
            <Stack alignItems="flex-end"  >
                <FormGridItemDesktop    
                  onTextChange={(val)=>{                            
                    dispatch(setDeductRentMortgage(convFloat(val)));
                   }}                    
                  inputVal={ DeductRentMortgage}/>
            </Stack>
        </Grid>
        <Grid item xs={6} >
            <Typography variant="h6"  color="grey.700" sx={{px:'3.5rem',pt:'1rem'}}>  {props.i18n.t("tab4_land")} </Typography>
        </Grid>
        <Grid item xs={6} >
            <Stack alignItems="flex-end"  >
                <FormGridItemDesktop  onTextChange={(val)=>{
                  dispatch(setDeductRatesGovRent(convFloat(val)));
                  }} inputVal={ DeductRatesGovRent}/>
            </Stack>
        </Grid>
        <Grid item xs={6} >
            <Typography variant="h6"  color="grey.700" sx={{px:'3.5rem',pt:'1rem'}}>  {props.i18n.t("tab4_manage")} </Typography>
        </Grid>
        <Grid item xs={6} >
            <Stack alignItems="flex-end"  >
                <FormGridItemDesktop  onTextChange={(val)=>{
                  dispatch(setDeductMgtFee(convFloat(val)));
                  }} 
                  inputVal={DeductMgtFee}/>
            </Stack>
        </Grid>        
      </Grid>
      <Stack  borderBottom={1} >
      </Stack>
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="h6" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("sub_total")} </Typography>     
        <TextField value={SubTotal1.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:1,width:"20rem",input: {py:0.1,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}} />
      </Stack>
      <Typography variant="h6" paragraph > <br/></Typography>
              
        <Grid container spacing={0} >
        <Grid item xs={6} >
              <Typography variant="h6"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_salarytax")} </Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{ dispatch(setDeductSalariesTax(convFloat(val)));}}  inputVal={DeductSalariesTax}/>
              </Stack>
          </Grid>
          <Grid item xs={6} >
              <Typography variant="h6"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_mpf")} </Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{dispatch(setDeductMPF(convFloat(val))); }}  inputVal={DeductMPF}/>
              </Stack>
          </Grid>
          <Grid item xs={6} >
              <Typography variant="h6"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_childsub")} </Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{dispatch(setDeductChildCareExp(convFloat(val))); }}  inputVal={ DeductChildCareExp}/>
              </Stack>
          </Grid>
          <Grid item xs={7} >
              <Typography variant="h5"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_schoolfee")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_schoolfee_tooltip_title","tab4_schoolfee_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
          </Grid>
          <Grid item xs={5} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{dispatch(setDeductSchoolFee(convFloat(val))); }}  inputVal={DeductSchoolFee}/>
              </Stack>
          </Grid>   
          <Grid item xs={6} >
              <Typography variant="h5"  color="grey.700" sx={{pt:'1rem'}}> {props.i18n.t("tab4_medicalexp")} <IconButton  sx={{p:0.4}}  onClick={()=>{handleClickOpen("tab4_medicalexp_tooltip_title","tab4_medicalexp_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{dispatch(setDeductAvgMedicalExp(convFloat(val))); }}  inputVal={ DeductAvgMedicalExp}/>
              </Stack>
          </Grid>  
          <Grid item xs={6} >
              <Typography variant="h5"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_maintpaid")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_maintpaid_tooltip_title","tab4_maintpaid_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >
                  <FormGridItemDesktop  onTextChange={(val)=>{dispatch(setDeductMaintPaid(convFloat(val))); }}  inputVal={DeductMaintPaid}/>
              </Stack>
          </Grid>   
          <Grid item xs={6} >
              <Typography variant="h5"  color="grey.700" sx={{pt:'1rem'}}>  {props.i18n.t("tab4_personalallow")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_personalallow_tooltip_title","tab4_personalallow_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
          </Grid>
          <Grid item xs={6} >
              <Stack alignItems="flex-end"  >                  
                  <TextField value={DeductPersonalAllowance.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:1,width:"20rem",input: {py:0.1,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
              </Stack>
          </Grid>     
        </Grid>
        <Stack  borderBottom={1} >
        </Stack>
        <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("sub_total")} </Typography>     
        <TextField value={SubTotal2.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})/*txtDeductSubTotal2*/} variant="outlined"  sx={{p:1,width:"20rem",input: {py:0.1,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
      </Stack>
      <Typography variant="h5" paragraph > <br/></Typography>
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
        <TextField value={DeductGrandTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0}) /*txtDeductGrandTotal*/} variant="outlined"  sx={{p:1,width:"20rem",input: {py:0.1,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
      </Stack>

      <Typography variant="h6" paragraph> {props.i18n.t("input_integer_only")}</Typography>
    
      <Grid container spacing={2} >
        <Grid item xs={4} >
          <Button variant="outlined"  sx={{fontSize: 22,}} onClick={()=>{props.onChange("asset")}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"  />{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} > <Stack direction="row" justifyContent="center" >  <Button  variant="outlined" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button></Stack></Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("result");props.onNext();}} sx={{fontSize: 22,}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
            <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
            <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalcApplicationFormMonthlyDeduct as ECalcApplicationFormMonthlyDeductDesktop}