import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField } from '@mui/material';
import {ECalApplicationFormBodyHeadDesktop,PatientAccordionDesktop,PatientAccordionHeaderDesktop,FamilyAccordionDesktop,SubtotalIncomeAccordionDesktop,
        convFloat,
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import { useDispatch, useSelector} from 'react-redux'
import { setState,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductTotal,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import {useEffectOnce} from '../common/common-lib.js';
import moment from 'moment';
import axios from 'axios';
const APIHost = window.APIHost;


function ECalApplicationFormBodyIncome(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;    
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;
    let isDrug = props.ecalcState.isDrug;
    let DeductTotal = props.ecalcState.DeductTotal;
    let DeductPersonalAllowance  = props.ecalcState.DeductPersonalAllowance;
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const [Summary_PatientAmount,setSummary_PatientAmount] = React.useState(0);
    const [lbl_drug_adfr_times_cont,setlbl_drug_adfr_times_cont] = React.useState(false);
    const [lbl_drug_no_item_cost,setlbl_drug_no_item_cost] = React.useState(false);
    const [Summary_SubsidyAmount,setSummary_SubsidyAmount] = React.useState(0);
    const [DrugSummary_LumpSumDeduct,setDrugSummary_LumpSumDeduct]=React.useState(0);
    const [ADFR_Result,setADFR_Result] = React.useState(0);
    const [ADFR_ContRatio_Result,setADFR_ContRatio_Result]=React.useState("");
    const [ADFR_LumpSumDeduct,setADFR_LumpSumDeduct]=React.useState(0);
    const [ADFR_IncomeFactor,setADFR_IncomeFactor] = React.useState(80);
    const [trSFFixAmt,setTrSFFixAmt]=React.useState(false);
    const [FixAmt_Result,setFixAmt_Result]=React.useState("");
    const [ContRatio_Result,setContRatio_Result]=React.useState("");
    
    const [msg_income_catg,setMsg_income_catg]=React.useState("");
    const [lbl_tab5_nondrug_asset_result,setlbl_tab5_nondrug_asset_result]=React.useState("");
    const [lbl_tab5_nondrug_asset_allowance,setlbl_tab5_nondrug_asset_allowance]=React.useState("");
    const [NonDrugSummary_ContRatio,setNonDrugSummary_ContRatio]=React.useState(0);
    

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);        
        
        
        if(allReset){ props.onReset();}else{
          
          
          let _householdIncomeAssets = householdIncomeAssets.slice(0,1).map((item) => {return { ...item, 
            salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});        
          
            dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal:0,householdAssetTotal,householdMemberCount:1,
            householdIncomeAssets:_householdIncomeAssets,DeductTotal,DeductPersonalAllowance,
          }));
          // props.calculateDeduct(()=>{
          //   setDiagTitle("");
          //   setDiagContent(props.i18n.t('msg_currency_out_of_range'));
          //   setOpen(true);   
          // });
         // dispatch(setSubTotal2(0));
         //               dispatch(setDeductRentMortgage(0));
          //             dispatch(setDeductRatesGovRent(0));
           //             dispatch(setDeductMgtFee(0));
            //            dispatch(setDeductSalariesTax(0));
            //            dispatch(setDeductMPF(0));
            //            dispatch(setDeductChildCareExp(0));
            //            dispatch(setDeductSchoolFee(0));
              //          dispatch(setDeductAvgMedicalExp(0));
              //          dispatch(setDeductMaintPaid(0));
                        dispatch(setDeductPersonalAllowance(0));
        }
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const hHouseholdAssetChg = (ev,id)=>{
       //console.log(ev);
       var MAX_INTEGER = 922337203685477;
       let _householdIncomeAssets = updateHouseholdincomeItem(householdIncomeAssets,id,ev.item,(ev.item==='patient_rel')?ev.val:convFloat(ev.val));
       
       let _subtotal = 
       _householdIncomeAssets[id].salary+
       _householdIncomeAssets[id].allowance+
       _householdIncomeAssets[id].double_pay+
       _householdIncomeAssets[id].benefit_in_kind+
       _householdIncomeAssets[id].pension+
       _householdIncomeAssets[id].rental_income+
       _householdIncomeAssets[id].sponsor+
       _householdIncomeAssets[id].other_income;

       _householdIncomeAssets = updateHouseholdincomeItem(_householdIncomeAssets,id,"subTotal",_subtotal);
       
       let _householdIncomeTotal=0;
       _householdIncomeAssets.forEach((record)=>{               
        _householdIncomeTotal +=record.subTotal;
       });
       if(_householdIncomeTotal >= MAX_INTEGER){
        _householdIncomeTotal = MAX_INTEGER
       }
       //console.log(_householdIncomeAssets); 
       dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal:_householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,
        DeductTotal,DeductPersonalAllowance,}));
   
    }
    const GetPersonalAllowance= ()=>{
      //input: hhm_size//HouseholdMemberCount
      //output: result//0: Fail; 1:Success
      //assessment_date//2023-11-07
      //hhm_size
      // personal_allowance
      axios({
        method: 'post',
        url: APIHost+'/getPersonalAllowance',
        data: {
          "hhm_size":householdMemberCount,
          "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
        },
       
      }).then((resp)=>{
        //console.log(resp.data);
        //dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
         //       DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
         dispatch(setDeductPersonalAllowance(convFloat(resp.data.result.personal_allowance)));
         dispatch(setDeductTotal(DeductTotal));

      }).catch((err)=>{
        console.log(err);
        setDiagTitle("Connection error");            
        setDiagContent(err.toString());
        setOpen(true);
      })
    }
    React.useEffect(()=>{
      props.calculateDeduct(()=>{
        setDiagTitle("");
        setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        setOpen(true);   
      });
    },[DeductPersonalAllowance,]);

    useEffectOnce(()=>{  
      GetPersonalAllowance();
    });

    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]   
    return(
      <>
      <Box   sx={{ p:2,m:2,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHeadDesktop  isDrug={isDrug} onChange={props.onChange} ecalcState={props.ecalcState} stepVal={props.stepVal}  enabledStep={props.enabledStep} i18n={props.i18n}/>
      <Typography variant="h6" paragraph> </Typography>
      <Grid container >
        <Grid item xs={3} alignContent="flex-start">        
            <Typography variant="h5" sx={{pt:1}} color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
        </Grid>    
        <Grid item xs={2}  >
            <Button  variant="text" startIcon={<img src="/images/blue_cross.png"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{   
              let _householdIncomeAssets = addHouseholdincomeItemExist(householdIncomeAssets, {    
                                                                              patient_rel:'',
                                                                              salary:0,
                                                                              allowance:0,
                                                                              double_pay:0,
                                                                              benefit_in_kind:0,
                                                                              pension:0,
                                                                              rental_income:0,
                                                                              sponsor:0,
                                                                              other_income:0,                                                                              
                                                                              subTotal:0,

                                                                              cash:0,
                                                                              saving:0,
                                                                              investment:0,
                                                                              insurance:0,
                                                                              property:0,
                                                                              carpark:0,
                                                                              land:0,
                                                                              other:0,
                                                                              subTotal2:0,
                                                                            });
                householdMemberCount =_householdIncomeAssets.length;
              dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));                                                                    
              
              }} > {props.i18n.t("tab2_btnAddMember")} </Button>  
        </Grid>  
        <Grid item xs={2} >
        </Grid>    
        <Grid item xs={5} >
        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
          <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{                                              
                          props.printPreviewPre({});       
                        }} >  {props.i18n.t("btnPreview")}</Button>
          
            <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
                /*confirm clear popup, initial */ 
                setDiagContent(props.i18n.t("msg_confirm_clear_all"));
                setDiagTitle(props.i18n.t("tab2_header"));
                setAllReset(true);
                setOpen2(true);
            }}>{props.i18n.t("btnClearAll")}</Button>
          </Stack> 
        </Grid>    
      </Grid>
      <Typography variant="h6" paragraph > </Typography>
      
      <Grid container spacing={2} >
        <Grid item xs={8} >
          <Typography variant="h5"  color="grey.700"sx={{pb:1}}>  {props.i18n.t("tab2_note")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab2_note_tooltip_title","tab2_note_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
        </Grid>
        
        <Grid item xs={4}  >
          <Stack direction="row" justifyContent="flex-end"> <Typography variant="h5" color="#f44336"> *</Typography><Typography variant="h5" > {props.i18n.t("required_field")}</Typography></Stack>
        </Grid>
      </Grid>
      
      <Typography variant="h5" paragraph > </Typography>

      <Stack direction="row"   justifyContent="flex-start" alignItems="flex-start" >       
        <PatientAccordionHeaderDesktop  householdincomeArry={householdIncomeAssets}  onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
            i18n={props.i18n}/>
            <Box sx={{overflow: "hidden",overflowX: "scroll",}}>
            <Stack direction="row"   justifyContent="flex-start" alignItems="flex-start" > 
        <PatientAccordionDesktop FamilyIdx={0} householdincomeArry={householdIncomeAssets} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
            onChange={(ev)=>{ hHouseholdAssetChg(ev,0) }} i18n={props.i18n}/>
      {householdIncomeAssets.map((income)=>{
        //console.log(income);
        if(income.id>0)
          return (
            <FamilyAccordionDesktop key={"fa"+income.id}  relationStr={relationStr}  FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} 
              onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}}
              onRemove={(id)=>{
                                let _householdIncomeAssets = removeHouseholdincomeItem(householdIncomeAssets,id);
                                
                                householdIncomeTotal=0;
                                _householdIncomeAssets.forEach((record)=>{                            
                                  householdIncomeTotal +=record.subTotal;
                                });
                                householdMemberCount =_householdIncomeAssets.length;
                                dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                              }} 
              onChange={(ev)=>{ hHouseholdAssetChg(ev,income.id)  }} i18n={props.i18n}/> )
      })}
		</Stack>
          </Box>
      <SubtotalIncomeAccordionDesktop householdincomeArry={householdIncomeAssets} i18n={props.i18n}/>
      </Stack>
      
      <Grid container spacing={2} >
        <Grid item xs={8} >
          <Typography variant="h6" > {props.i18n.t("input_integer_only")}</Typography>
        </Grid>
        <Grid item xs={4} >
          <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
            <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
            <TextField value={householdIncomeTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:1,width:"10rem",input: {p:0.5,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} >
        <Grid item xs={3} >
          <Button variant="outlined" onClick={()=>{props.onChange("info")}}  sx={{fontSize: 22,backgroundColor:'grey.200',}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={6} > <Stack direction="row" justifyContent="center" >  <Button  variant="outlined" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688',backgroundColor:'grey.200'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button></Stack></Grid>
        <Grid item xs={3} ><Stack  direction="row"  justifyContent="flex-end">
          <Button variant="outlined" onClick={()=>{props.onChange("asset");props.onNext();}}  sx={{fontSize: 22,backgroundColor:'grey.200',}}>
            {props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button>
            </Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalApplicationFormBodyIncome as ECalApplicationFormBodyIncomeDesktop}