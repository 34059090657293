import * as React from 'react';
import {Stack,Typography, Grid, Box, Button,RadioGroup,Radio,FormControlLabel,IconButton,   Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
        TextField,} from '@mui/material';
import {ECalApplicationFormBodyHead,convFloat} from './ECalApplicationFormCommon.js';
import {useSelector, useDispatch, } from 'react-redux'
import { setState,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import moment from 'moment';  
import {useEffectOnce} from '../common/common-lib.js';
import axios from 'axios';
const APIHost = window.APIHost;

function ECalApplicationFormBodyInfo(props){
  let householdAssetTotal = props.ecalcState.householdAssetTotal;
  let householdMemberCount = props.ecalcState.householdMemberCount;  
  let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
  
  let DeductPersonalAllowance  = props.ecalcState.DeductPersonalAllowance;
  let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
  const [isDrug,setIsDrug] = React.useState(props.ecalcState.isDrug);
  const [EstimatedItemCost,setEstimatedItemCost] = React.useState(props.ecalcState.EstimatedItemCost);
  let DeductTotal = props.ecalcState.DeductTotal;
  
  const [recurrentApp,setRecurrentApp] = React.useState("false");
  const [_CCFMD,set_CCFMD] = React.useState(props.ecalcState._CCFMD);


  const dispatch = useDispatch()  
  
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [allReset,setAllReset] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const curTextConv=(textVal)=>{
    if(textVal === 0) return '$0'
    return textVal
  }
const handleOk2=()=>{
    setOpen2(false);

    dispatch(setSubTotal2(0));
    
    if(allReset){ dispatch(setSubTotal2(0));
                       
      //GetPersonalAllowance();
      if(allReset){ props.onReset();}else{
        let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});    
        dispatch(setState({isDrug,_CCFMD,recurrentApp,householdIncomeTotal:0,householdAssetTotal:0,householdMemberCount:1,
          householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance:0,EstimatedItemCost:0,
        }));
        dispatch(setDeductRentMortgage(0));
        dispatch(setDeductRatesGovRent(0));
        dispatch(setDeductMgtFee(0));
        dispatch(setDeductSalariesTax(0));
        dispatch(setDeductMPF(0));
        dispatch(setDeductChildCareExp(0));
        dispatch(setDeductSchoolFee(0));
        dispatch(setDeductAvgMedicalExp(0));
        dispatch(setDeductMaintPaid(0));
        dispatch(setDeductPersonalAllowance(0));

      }}
  }
const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
    setDiagTitle(props.i18n.t(helpTipsTitleKey));
    setDiagContent(props.i18n.t(helpTipsKey));
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};
const GetPersonalAllowance= ()=>{
  //input: hhm_size//HouseholdMemberCount
  //output: result//0: Fail; 1:Success
  //assessment_date//2023-11-07
  //hhm_size
  // personal_allowance
  axios({
    method: 'post',
    url: APIHost+'/getPersonalAllowance',
    data: {
      "hhm_size":householdMemberCount,
      "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
    },
   
  }).then((resp)=>{
    //console.log(resp.data);
    dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
            DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
    props.calculateDeduct(()=>{
      setDiagTitle("");
      setDiagContent(props.i18n.t('msg_currency_out_of_range'));
      setOpen(true);   
    });            
  }).catch((err)=>{
    console.log(err);
    setDiagTitle("Connection error");            
    setDiagContent(err.toString());
    setOpen(true);
  })
}
useEffectOnce(()=>{    
  GetPersonalAllowance();
});


const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
const Info1=(props)=>{
  return (    <>      
      <Typography paragraph variant="h6" > {props.i18n.t("tab1_lblRecurrent")}:* </Typography>
      
          <RadioGroup row value={ recurrentApp} onChange={(ev)=>{
              setRecurrentApp(ev.target.value);
              dispatch(setState({isDrug,_CCFMD,recurrentApp:ev.target.value,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets, DeductTotal,
                DeductPersonalAllowance,
              }));                   
          }}  >
            <FormControlLabel value="true" control={<Radio />} label={<Typography  variant="h6"  display="inline">{props.i18n.t("tab1_label_radRecurrentYes")}</Typography> } />
            <FormControlLabel value="false" control={<Radio />} label={<Typography variant="h6"  display="inline">{props.i18n.t("tab1_label_radRecurrentNo")}</Typography> } />
          </RadioGroup>  
     
      
      <Typography  paragraph ><br/> </Typography>
    <Box  sx={{border: 1, px:2 , backgroundColor: 'grey.100'}} >
      <Stack>
        <Typography  paragraph > </Typography>
        <Typography  component={'span'} variant="h6"  align="center" sx={{textDecoration: 'underline'}}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_title")}}/></Typography>
      </Stack> 
      <Box height={ (window.innerHeight/2.5)+'px'} sx={{border: 1, p:1 , overflow: "hidden",overflowY: "scroll", backgroundColor: '#FFF'}} >
       
      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>

      <Stack direction="row" ><Typography  variant="h6"> 1.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li1")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li1_1")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>
       
      <Stack direction="row" ><Typography  variant="h6"> 2.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li2")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li2_1")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row" ><Typography  variant="h6"> 3.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li3")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li3_1")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p2_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Typography variant="body" paragraph> {props.i18n.t("tab1_instruct_p2_1")}</Typography>
      <Typography variant="body" paragraph> {props.i18n.t("tab1_instruct_p2_2")}</Typography>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p3_drug")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_li")}}/></Typography>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row" ><Typography variant="body1" sx={{px:0.5}} >^</Typography><Typography variant="body1"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_remarks_ued")}}/></Typography></Stack>
      <Stack direction="row" ><Typography variant="body1" sx={{px:0.5}}>*</Typography><Typography variant="body1"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_remarks")}}/></Typography></Stack>
      
      </Box>   
      <Typography variant="h5" ><br/></Typography> 
    </Box>    
  </>
  
  )
}

const Info2=(props)=>{
  return (    
  <>      
    <Typography paragraph variant="h6" > {props.i18n.t("tab1_lblCCFMD")}: </Typography>
          <RadioGroup row value={ _CCFMD} onChange={(ev)=>{ 
            set_CCFMD(ev.target.value)
            dispatch(setState({isDrug,_CCFMD:ev.target.value,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets, DeductTotal,
              DeductPersonalAllowance,
            }));
          }}  >
            <FormControlLabel value="true" control={<Radio />} label={<Typography component={'span'} variant="h6"  display="inline">{props.i18n.t("tab1_label_radRecurrentYes")}</Typography> } />
            <FormControlLabel value="false" control={<Radio />} label={<Typography component={'span'} variant="h6"  display="inline">{props.i18n.t("tab1_label_radRecurrentNo")}</Typography> } />
          </RadioGroup>            
    <Typography  paragraph ><br/> </Typography>
    <Box  sx={{border: 1, px:2 , backgroundColor: 'grey.100'}} >
      <Stack>
        <Typography  paragraph > </Typography>
        <Typography  component={'span'} variant="h6"  align="center" sx={{textDecoration: 'underline'}}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_title_2")}}/></Typography>
      </Stack> 
      <Box height={ (window.innerHeight/2.5)+'px'} sx={{border: 1, p:1 , overflow: "hidden",overflowY: "scroll", backgroundColor: '#FFF'}} >
     
      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>

      <Stack direction="row" ><Typography  variant="h6"> 1.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li1")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li1_2")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>
       
      <Stack direction="row" ><Typography  variant="h6"> 2.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li2")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li2_1")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row" ><Typography  variant="h6"> 3.</Typography><Typography paragraph variant="h6" > {props.i18n.t("tab1_instruct_p1_li3")} </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Stack direction="row" sx={{px:2}} ><Typography variant="body"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li3_1")}}/></Typography></Stack>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p2_title")}  </Typography> <Typography variant="h6" >: </Typography></Stack>
      <Typography variant="body" paragraph> {props.i18n.t("tab1_instruct_p2_1")}</Typography>
      <Typography variant="body" paragraph> {props.i18n.t("tab1_instruct_p2_2")}</Typography>
      <Typography variant="h6" ><br/></Typography>

      <Stack direction="row"><Typography paragraph variant="h6" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p3_nondrug")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
      <Typography variant="body1"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_nondrug_li")}}/></Typography>
      <Typography variant="body1" ><br/></Typography>
      
     
      </Box>
      <Typography variant="h5" ><br/></Typography>
    </Box>
  </>
  )
}
const ShowInfo=(props)=>{
  return isDrug=="true"?<Info1 i18n={props.i18n}/>:<Info2 i18n={props.i18n}/>
}
return(
    <>
    <Box   sx={{ p:1,m:1,border:1, borderRadius:1 }} >
    <ECalApplicationFormBodyHead  isDrug={isDrug} onChange={ (newVal)=>{
             if(EstimatedItemCost <=0){
              setDiagTitle("");
              setDiagContent(props.i18n.t('msg_missingitemcost'));
              setOpen(true);  
            }else{
              props.onChange(newVal);
            }
    }} stepVal={props.stepVal} enabledStep={props.enabledStep}/>

    <Grid container spacing={2} sx={{ py:1 }}>
      <Grid item xs={12} borderBottom={1} sx={{ ml:1 }}>
        <Typography  paragraph variant="h6"  color="grey.500" align="left"  display="inline">{props.i18n.t("tab1_header")}</Typography>
      </Grid>
      </Grid>
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
          
          <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 18,color: '#009688'}} onClick={()=>{
              /*confirm clear popup, initial */ 
              setDiagContent(props.i18n.t("msg_confirm_clear_all"));
              setDiagTitle(props.i18n.t("tab3_header"));
              setAllReset(true);
              setOpen2(true);
              }}>{props.i18n.t("btnClearAll")}</Button>
        </Stack> 
    
      <Typography  variant="h6" align="left"  display="inline" paragraph>{props.i18n.t("tab1_subsidy_type")}?</Typography>
      <Stack > 
            <RadioGroup row value={ isDrug} onChange={(ev)=>{ 
                  setIsDrug(ev.target.value);       
                  //dispatch(setState({isDrug:ev.target.value,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));                  
                  //GetPersonalAllowance();                  
                  let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});    
                    dispatch(setState({isDrug:ev.target.value,_CCFMD,recurrentApp,householdIncomeTotal:0,householdAssetTotal:0,householdMemberCount:1,
                      householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance:0,EstimatedItemCost:0,
                    }));
                    dispatch(setSubTotal2(0));
                    dispatch(setDeductRentMortgage(0));
                    dispatch(setDeductRatesGovRent(0));
                    dispatch(setDeductMgtFee(0));
                    dispatch(setDeductSalariesTax(0));
                    dispatch(setDeductMPF(0));
                    dispatch(setDeductChildCareExp(0));
                    dispatch(setDeductSchoolFee(0));
                    dispatch(setDeductAvgMedicalExp(0));
                    dispatch(setDeductMaintPaid(0));
                    dispatch(setDeductPersonalAllowance(0));
                }}  >
              <FormControlLabel value="true" control={<Radio />} label={<Typography variant="h6" component={'span'} display="inline">{props.i18n.t("tab1_label_radDrugItem")}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{handleClickOpen("tab1_radDrugItem_tooltip_title","tab1_radDrugItem_tooltip_desc") }} ><img src="images/question_mark.png" width="18px"/></IconButton></Typography> } />
              <FormControlLabel value="false" control={<Radio />} label={<Typography variant="h6"component={'span'}  display="inline">{props.i18n.t("tab1_label_radNonDrugItem")}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{handleClickOpen("tab1_radNonDrugItem_tooltip_title","tab1_radNonDrugItem_tooltip_desc") }} ><img src="images/question_mark.png" width="18px"/></IconButton></Typography> } />
            </RadioGroup>  
            
          </Stack>
      <Typography  variant="h6" align="left"  display="inline">{props.i18n.t("tab1_lblEstimatedItemCost")}:</Typography>
      
      <Stack direction="row">
      <TextField value={ curTextConv(EstimatedItemCost)} onChange={(ev)=>{                 
                if(isFinite(parseFloat(ev.target.value))){
                  if(parseFloat(ev.target.value)<=999999999){
                    setEstimatedItemCost(parseFloat(ev.target.value));
                    if(ev.nativeEvent.inputType == "deleteContentBackward"||
                        ev.nativeEvent.inputType == "deleteContentForward")
                        if(parseFloat(ev.target.value)<10)
                          setEstimatedItemCost(0); 
                    dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost:ev.target.value,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                 
                  }
                }
              }} 
              onBlur={(ev)=>{ 
                if(isFinite(parseFloat(ev.target.value))){
                  setEstimatedItemCost(parseFloat(ev.target.value));
                  dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost:ev.target.value,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                } 
                  
              }} variant="outlined"  
              sx={{ width:"12rem", input: {textAlign: "right",backgroundColor:'#FFF',fontSize:"1.2rem"}}}
              inputProps={{sx:{py:.15}}}
              align="center" 
              />

          <Typography variant="h6" paragraph> *{props.i18n.t("required_field")}</Typography>
      </Stack>
      
      
    
    
      <ShowInfo i18n={props.i18n}/> 
    <Typography  paragraph > </Typography>
    <Stack  direction="row"  justifyContent="flex-end">
        <Button variant="outlined" 
            onClick={()=>{
              if(EstimatedItemCost <=0){
                setDiagTitle("");
                setDiagContent(props.i18n.t('msg_missingitemcost'));
                setOpen(true);  
              }else{
                props.onChange("income");
                props.onNext();
              }
              
            }}>
            {props.i18n.t("btnNext")}
            <img src="/images/arrow_btn.gif" width="14px" height="14px"/>
        </Button>
    </Stack>
    <Dialog
          open={open}
          onClose={handleClose}
          fullwidth 
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >     
              <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
    </Box>
  
    </>
  )
}

export {ECalApplicationFormBodyInfo}