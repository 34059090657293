import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField } from '@mui/material';
import {ECalApplicationFormBodyHead,FormGridItemGrey,FormGridItem,
        convFloat, 
        addHouseholdincomeItemExist,updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import { useDispatch, useSelector} from 'react-redux'
import { setState,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import moment from 'moment';
import axios from 'axios';
import {useEffectOnce} from '../common/common-lib.js';
const APIHost = window.APIHost;



function ECalcApplicationFormMonthlyDeduct(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    let isDrug = props.ecalcState.isDrug;


    const DeductRentMortgage  =useSelector((state)=>state.ecalc.DeductRentMortgage);
    const DeductRatesGovRent  =useSelector((state)=>state.ecalc.DeductRatesGovRent);
    const DeductMgtFee  =useSelector((state)=>state.ecalc.DeductMgtFee);
    
    const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax);
    const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF);
    const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp);
    const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee);
    const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp);
    const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid);
    const DeductPersonalAllowance =useSelector((state)=>state.ecalc.DeductPersonalAllowance);

    const SubTotal1 =useSelector((state)=>state.ecalc.SubTotal1);
    const DeductGrandTotal =useSelector((state)=>state.ecalc.DeductGrandTotal);
    const DeductTotal =useSelector((state)=>state.ecalc.DeductTotal);
    
    const SubTotal2 =useSelector((state)=>state.ecalc.SubTotal2??0);
    
    
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;
    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    const lang = useSelector((state)=> state.config.lang);
    const [reviewResult,setReviewResult] =React.useState(props.i18n.t("tab5_eligible"));
    const [review_income,setReview_income] = React.useState(props.i18n.t("tab5_income_01_b_50"));
    const [review_asset,setReview_asset] = React.useState( props.i18n.t("tab5_asset_01_b_lmt"));
    const [reviewResultTip,setReviewResultTip] = React.useState(false);
    const [PatientAmount,setPatientAmount] = React.useState(0);
    const [WaivedAmount,setWaivedAmount] = React.useState(0);
    const [AssessmentResult,setAssessmentResult] = React.useState("");

    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);
    
                       
        //GetPersonalAllowance();
        if(allReset){ props.onReset();}else{
          let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item,
          };});    
          dispatch(setState({isDrug,_CCFMD,recurrentApp,householdIncomeTotal,householdAssetTotal,householdMemberCount,
            householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance,EstimatedItemCost,
          }));
          dispatch(setDeductRentMortgage(0));
          dispatch(setDeductRatesGovRent(0));
          dispatch(setDeductMgtFee(0));
          dispatch(setDeductSalariesTax(0));
          dispatch(setDeductMPF(0));
          dispatch(setDeductChildCareExp(0));
          dispatch(setDeductSchoolFee(0));
          dispatch(setDeductAvgMedicalExp(0));
          dispatch(setDeductMaintPaid(0));

        }
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    
    



    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab2_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab2_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]   
    const GetPersonalAllowance= ()=>{
      
      //input: hhm_size//HouseholdMemberCount
      //output: result//0: Fail; 1:Success
      //assessment_date//2023-11-07
      //hhm_size
      // personal_allowance
      axios({
        method: 'post',
        url: APIHost+'/getPersonalAllowance',
        data: {
          "hhm_size":householdMemberCount,
          "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
        },
       
      }).then((resp)=>{
        // console.log(resp.data);
        dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
                DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
        props.calculateDeduct(()=>{
          setDiagTitle("");
          setDiagContent(props.i18n.t('msg_currency_out_of_range'));
          setOpen(true);   
        });                
      }).catch((err)=>{
        console.log(err);
        setDiagTitle("Connection error");            
        setDiagContent(err.toString());
        setOpen(true);
      })
    }

    
    useEffectOnce(()=>{
      GetPersonalAllowance();
    
    });
    React.useEffect(()=>{
      props.calculateDeduct(()=>{
        setDiagTitle("");
        setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        setOpen(true);   
      });
    },[DeductRentMortgage,DeductRatesGovRent,DeductMgtFee,DeductSalariesTax,
      DeductMPF,
      DeductChildCareExp,
      DeductSchoolFee,
      DeductAvgMedicalExp,
      DeductMaintPaid,]);
    return(
      <>
      <Box   sx={{ p:1,my:1,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHead isDrug={isDrug} onChange={props.onChange} ecalcState={props.ecalcState} stepVal={props.stepVal}  enabledStep={props.enabledStep} i18n={props.i18n}/>
      <Typography variant="h6" paragraph> </Typography>
      <Typography variant="h6" sx={{pt:1}} color="grey.700" > {props.i18n.t("tab4_header")}$ {householdMemberCount}</Typography>
      <Typography variant="h6" color="grey.700" > {props.i18n.t("tab2_household_count")}: {householdMemberCount}</Typography>
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 16,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/ 
            setDiagContent(props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab2_header")));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(false);
            setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button>
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 16,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, initial */ 
            setDiagContent(props.i18n.t("msg_confirm_clear_all"));
            setDiagTitle(props.i18n.t("tab2_header"));
            setAllReset(true);
            setOpen2(true);
        }}>{props.i18n.t("btnClearAll")}</Button>
      </Stack> 
      

      <Typography variant="h6" paragraph > </Typography>      
      <Typography variant="body"  color="grey.700" paragraph sx={{px:0.5}}>  {props.i18n.t("tab4_note")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_note_tooltip_title","tab4_note_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
      <Typography variant="body" color="grey.700"  > {props.i18n.t("input_integer_only")}</Typography>
      <Typography variant="h6" paragraph > </Typography>
      <Typography variant="body"  color="grey.700" >  {props.i18n.t("tab4_deduct_sum")} <IconButton  sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab4_deduct_sum_tooltip_title","tab4_deduct_sum_tooltip_desc")}} ><img src="images/question_mark.png"  width="18px"/></IconButton></Typography>
        
      
      
      <Grid container spacing={0} >
        <FormGridItemGrey itemName={props.i18n.t("tab4_rent")} onTextChange={(val)=>{dispatch(setDeductRentMortgage(convFloat(val)));}}  inputVal={ DeductRentMortgage}/>
        <FormGridItem itemName={props.i18n.t("tab4_land")} onTextChange={(val)=>{dispatch(setDeductRatesGovRent(convFloat(val)));}}  inputVal={ DeductRatesGovRent}/>
        <FormGridItemGrey itemName={props.i18n.t("tab4_manage")} onTextChange={(val)=>{ dispatch(setDeductMgtFee(convFloat(val)));}}  inputVal={DeductMgtFee}/>
        
      </Grid>
      <Stack  borderBottom={1} >
      </Stack>
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="body" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("sub_total")} </Typography>     
        <TextField value={"$"+SubTotal1} variant="outlined"  sx={{p:0.5,width:"7rem",input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1rem"}}} inputProps={{sx:{py:0.5}}}/>
      </Stack>
      
      
        
        <Grid container spacing={0} >
            <Grid item xs={12} sx={{backgroundColor:'grey.200'}}><Typography variant="h5" display="inline"><br/></Typography></Grid>
            <FormGridItem itemName={props.i18n.t("tab4_salarytax")} onTextChange={(val)=>{dispatch(setDeductSalariesTax(convFloat(val)))}}  inputVal={ DeductSalariesTax}/>
            <FormGridItemGrey itemName={props.i18n.t("tab4_mpf")} onTextChange={(val)=>{dispatch(setDeductMPF(convFloat(val)))}}  inputVal={ DeductMPF}/>
            <FormGridItem itemName={props.i18n.t("tab4_childsub")} onTextChange={(val)=>{dispatch(setDeductChildCareExp(convFloat(val)))}}  inputVal={ DeductChildCareExp}/>
            <FormGridItemGrey itemName={props.i18n.t("tab4_schoolfee")} hasTip={true} onHelpClick={()=>{handleClickOpen("tab4_schoolfee_tooltip_title","tab4_schoolfee_tooltip_desc")}} onTextChange={(val)=>{dispatch(setDeductSchoolFee(convFloat(val)))}}  inputVal={ DeductSchoolFee}/>
            <FormGridItem itemName={props.i18n.t("tab4_medicalexp")} hasTip={true} onHelpClick={()=>{handleClickOpen("tab4_medicalexp_tooltip_title","tab4_medicalexp_tooltip_desc")}} onTextChange={(val)=>{dispatch(setDeductAvgMedicalExp(convFloat(val)))}}  inputVal={ DeductAvgMedicalExp}/>
            <FormGridItemGrey itemName={props.i18n.t("tab4_maintpaid")} hasTip={true} onHelpClick={()=>{handleClickOpen("tab4_maintpaid_tooltip_title","tab4_maintpaid_tooltip_desc")}} onTextChange={(val)=>{dispatch(setDeductMaintPaid((convFloat(val))))}}  inputVal={ DeductMaintPaid}/>
            <FormGridItem itemName={props.i18n.t("tab4_personalallow")} hasTip={true} onHelpClick={()=>{handleClickOpen("tab4_personalallow_tooltip_title","tab4_personalallow_tooltip_desc")}} onTextChange={(val)=>{}}  inputVal={ DeductPersonalAllowance}/>
        </Grid>
        <Stack  borderBottom={1} >
        </Stack>
        <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
            <Typography variant="body" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("sub_total")} </Typography>     
            <TextField value={"$"+SubTotal2} variant="outlined"  sx={{p:0.5,width:"7rem",input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1rem"}}} inputProps={{sx:{py:0.5}}}/>
        </Stack>
        <Typography variant="h6" paragraph > </Typography>     
      <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
        <Typography variant="body" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")} </Typography>     
        <TextField value={"$"+DeductGrandTotal} variant="outlined"  sx={{p:0.5,width:"7rem",input: {textAlign: "right",backgroundColor:'grey.200',fontSize:"1rem"}}} inputProps={{sx:{py:0.5}}}/>
      </Stack>

      <Typography variant="h6" paragraph> </Typography>
    
      <Grid container spacing={2} >
        <Grid item xs={4} >
          <Button variant="outlined" onClick={()=>{props.onChange("asset")}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} > 
        </Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{props.onChange("result");props.onNext();}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>
      
      </Box>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
            <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
            <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </>)
  }

  export {ECalcApplicationFormMonthlyDeduct }