import * as React from 'react';
import {Stack,Typography, Grid,Box, Button,Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
    IconButton,TextField ,Skeleton      } from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import {ECalApplicationFormBodyHeadDesktop,convFloat} from './ECalApplicationFormCommon.js';
import {useSelector,useDispatch} from 'react-redux';
import {useEffectOnce} from '../common/common-lib.js';
import { setState,} from '../store/eCalcStore.js';
import { CompressOutlined } from '@mui/icons-material';

const APIHost = window.APIHost;

function ECalApplicationFormBodyResult(props){
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    
    let householdMemberCount = props.ecalcState.householdMemberCount;
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
  
    const lang = useSelector((state)=> state.config.lang);
    const [patient_amt_msg,setPatient_amt_msg] = React.useState(false);
    const isDrug =  props.ecalcState.isDrug;
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;
    let DeductTotal = props.ecalcState.DeductTotal;
    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;
    const DeductRentMortgage  =useSelector((state)=>state.ecalc.DeductRentMortgage);
    const DeductRatesGovRent  =useSelector((state)=>state.ecalc.DeductRatesGovRent);
    const DeductMgtFee  =useSelector((state)=>state.ecalc.DeductMgtFee);
    const DeductSalariesTax =useSelector((state)=>state.ecalc.DeductSalariesTax);
    const DeductMPF   =useSelector((state)=>state.ecalc.DeductMPF);
    const DeductChildCareExp =useSelector((state)=>state.ecalc.DeductChildCareExp);
    const DeductSchoolFee   =useSelector((state)=>state.ecalc.DeductSchoolFee);
    const DeductAvgMedicalExp   =useSelector((state)=>state.ecalc.DeductAvgMedicalExp);
    const DeductMaintPaid =useSelector((state)=>state.ecalc.DeductMaintPaid);
    const DeductPersonalAllowance =useSelector((state)=>state.ecalc.DeductPersonalAllowance);

    const SubTotal1 =useSelector((state)=>state.ecalc.SubTotal1);
    const DeductGrandTotal =useSelector((state)=>state.ecalc.DeductGrandTotal);
    
    
    const SubTotal2 =useSelector((state)=>state.ecalc.SubTotal2??0);

    const [Summary_PatientAmount,setSummary_PatientAmount] = React.useState(0);
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab5_noneligible_tooltip_title"));    
    const [diagContent, setDiagContent] = React.useState( props.i18n.t("tab5_noneligible_tooltip_desc") );
    const [drug_patient_fix_cont,setDrug_patient_fix_cont] = React.useState(false);
    const [drug_formula_adfr,setDrug_formula_adfr] = React.useState(false);
    const [adfr_income_factor,setAdfr_income_factor] = React.useState(false);
    const [ADFR_IncomeFactor,setADFR_IncomeFactor] = React.useState(80);
    const [ADFR_Result,setADFR_Result] = React.useState(0);
    const [ADFR_Result2,setADFR_Result2] = React.useState(0);
    const [ADFR_lblZero,setADFR_lblZero] = React.useState(false);
    const [Summary_SubsidyAmount,setSummary_SubsidyAmount] = React.useState(0);
    const [NonDrugSummary_Asset,setNonDrugSummary_Asset] = React.useState(0);
    const [lbl_drug_no_item_cost,setlbl_drug_no_item_cost] = React.useState(false);
    const [lbl_drug_adfr_times_cont,setlbl_drug_adfr_times_cont] = React.useState(false);
    const [lbl_drug_max_cont_amt_or_item_cost,setlbl_drug_max_cont_amt_or_item_cost]=React.useState(false);
    const [trSFFixAmt,setTrSFFixAmt]=React.useState(false);
    const [trSFContRatio,setTrSFContRatio]=React.useState(false);
    const [trEstimateItemCost,setTrEstimateItemCost]=React.useState(false);
    const [lbl_tab5_nondrug_income_below_mmdhi,setlbl_tab5_nondrug_income_below_mmdhi]=React.useState(false);
    const [lbl_tab5_nondrug_income_equal_mmdhi,setlbl_tab5_nondrug_income_equal_mmdhi]=React.useState(false);
    const [lbl_tab5_nondrug_income_above_mmdhi,setlbl_tab5_nondrug_income_above_mmdhi]=React.useState(false);
    const [lbl_tab5_nondrug_asset_result,setlbl_tab5_nondrug_asset_result]=React.useState("");
    const [lbl_tab5_nondrug_asset_allowance,setlbl_tab5_nondrug_asset_allowance]=React.useState("");
    const [tableNonDrugSummary2,setTableNonDrugSummary2]=React.useState(false);
    const [NonDrugSummary_EstimatedItemCost2,setNonDrugSummary_EstimatedItemCost2]=React.useState("");
    const [NonDrugSummary_EstimatedItemCost,setNonDrugSummary_EstimatedItemCost]=React.useState("");
    const [NonDrugSummary_ContRatio,setNonDrugSummary_ContRatio]=React.useState(0);
    const [ContRatio_Result,setContRatio_Result]=React.useState("");
    const [NonDrugSummary_Result,setNonDrugSummary_Result]=React.useState(0);
    const [NonDrugSummary_HouseholdIncome,setNonDrugSummary_HouseholdIncome]=React.useState(0);
    const [DrugSummary_HouseholdIncome,setDrugSummary_HouseholdIncome]=React.useState(0);
    const [DrugSummary_EstimatedItemCost,setDrugSummary_EstimatedItemCost]=React.useState(0);
    const [DrugSummary_Expenditure,setDrugSummary_Expenditure]=React.useState(0);
    const [DrugSummary_Asset,setDrugSummary_Asset]=React.useState(0);
    const [DrugSummary_LumpSumDeduct,setDrugSummary_LumpSumDeduct]=React.useState(0);
    const [ADFR_HouseholdIncome,setADFR_HouseholdIncome]=React.useState(0);
    const [ADFR_Expenditure,setADFR_Expenditure]=React.useState(0);
    const [ADFR_Asset,setADFR_Asset]=React.useState(0);
    const [ADFR_LumpSumDeduct,setADFR_LumpSumDeduct]=React.useState(0);


    const [ADFR_ContRatio_Result,setADFR_ContRatio_Result]=React.useState("");
    const [FixAmt_Result,setFixAmt_Result]=React.useState("");
    const [msg_income_catg,setMsg_income_catg]=React.useState("");
    const [open, setOpen] = React.useState(false);    
    const dispatch = useDispatch()

    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
        setDiagTitle(props.i18n.t(helpTipsTitleKey));
        setDiagContent(props.i18n.t(helpTipsKey));
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    function isEmpty(val){
        return val == null
    }
 
    const GetPersonalAllowance= ()=>{      
        axios({
            method: 'post',
            url: APIHost+'/getPersonalAllowance',
            data: {
              "hhm_size":householdMemberCount,
              "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
            },
           
          }).then((resp)=>{
             //0: Fail; 1:Success
            if(resp.data.result == '1'){
                // console.log(resp.data);
                dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
                        DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
            props.calculateDeduct(()=>{
                setDiagTitle("");
                setDiagContent(props.i18n.t('msg_currency_out_of_range'));
                setOpen(true);   
                });
            }else{
                if(!isEmpty(resp.data.Message)){
                    var error_msg = resp.data.Message;
                    setDiagContent('Fail Retrieve Data\n' + error_msg);
                    setDiagTitle("");                        
                    setOpen(true);
                }
            }
            
          }).catch((err)=>{
            console.log(err);
            setDiagTitle("Connection error");            
            setDiagContent(err.toString());
            setOpen(true);
          })
    }
    const DrugAssessment =()=>{
        //GET_DRUG_ASSESSMENT
        //input:
        //drug_cost:EstimatedItemCost
        //hhm_size:HouseholdMemberCount
        //hh_income:HouseholdIncomeTotal
        //expenditure:DeductTotal
        //income_factor:recurrentApp&&(HouseholdIncomeTotal>DeductTotal)?80:100
        //asset:HouseholdAssetTotal
        //output: 
        //result //0: Fail; 1:Success
        //assessment_date
        //drug_cost
        //hhm_size
        //hh_income
        //expenditure
        //asset
        //lumpsum_dect
        //actual_lumpsum_dect
        //adfr
        //actual_adfr
        //reco_result
        //sf_cont_ratio
        //sf_fix_cont_amt
        //patient_cont_amt
        //sf_subsidy_amt
        axios({
            method: 'post',
            url: APIHost+'/DRUGASSESSMENT',
            data: {
                drug_cost:EstimatedItemCost,
                hhm_size:householdMemberCount,
                hh_income:householdIncomeTotal,
                expenditure:DeductTotal,
                income_factor:(recurrentApp=="true")&&(householdIncomeTotal>DeductTotal)?80:100,
                asset:householdAssetTotal,
                refno:props.refno,
            },
           
          }).then((resp)=>{
            // console.log(resp.data);
            
            if(resp.data.retCde != '0'){
                if(!isEmpty(resp.data.Message)){
                    var error_msg = resp.data.Message;
                    setDiagContent('Fail Retrieve Data\n' + error_msg);
                    setDiagTitle("");                        
                    setOpen(true);
                    return;
                }
            }
            
            let is_ued = false
            let drug_cost = resp.data.result.drug_cost;
            let patient_cont_amt = convFloat(resp.data.result.patient_cont_amt);
            let sf_subsidy_amt = convFloat(resp.data.result.sf_subsidy_amt);
            let income_factor = convFloat(resp.data.result.income_factor);
            let adfr = convFloat(resp.data.result.adfr);
            let sf_cont_ratio = convFloat(resp.data.result.sf_cont_ratio);
            let actual_adfr = convFloat(resp.data.result.actual_adfr);
            let sf_fix_cont_amt = convFloat(resp.data.result.sf_fix_cont_amt);
            let lumpsum_dect = convFloat(resp.data.result.lumpsum_dect);
            let actual_lumpsum_dect = convFloat(resp.data.result.actual_lumpsum_dect);
            setADFR_IncomeFactor(convFloat(resp.data.result.income_factor));
            setDrugSummary_HouseholdIncome(householdIncomeTotal);
            
            setDrugSummary_Expenditure(DeductTotal);
            setDrugSummary_Asset(householdAssetTotal);
            setDrugSummary_LumpSumDeduct(convFloat(lumpsum_dect));
            setADFR_HouseholdIncome(householdIncomeTotal);
            setADFR_Expenditure(DeductTotal);
            setADFR_Asset(householdAssetTotal);
            setADFR_LumpSumDeduct(actual_lumpsum_dect);

            setDrugSummary_EstimatedItemCost(convFloat(drug_cost));
            if (drug_cost > 1000000 && patient_cont_amt > 1000000) {
                is_ued = true;
                patient_cont_amt = 1000000;
                sf_subsidy_amt = drug_cost - patient_cont_amt;
                //setDrugSummary_EstimatedItemCost(1000000);
                setPatient_amt_msg(true);
                setDrug_patient_fix_cont(true);            
            } else {                
                setPatient_amt_msg(false);
                setDrug_patient_fix_cont(false);
            }
            setSummary_PatientAmount(patient_cont_amt );

        
            setAdfr_income_factor(false);
            setDrug_formula_adfr(false);

            // console.log("householdIncomeTotal>DeductTotal:",householdIncomeTotal>DeductTotal);
            // console.log("income_factor:",income_factor);
            // console.log("recurrentApp:",recurrentApp);
            // console.log(recurrentApp == "true")
            if (recurrentApp == "true") {  
               
                setDrug_formula_adfr(true);
                
                setAdfr_income_factor(false);
                if (income_factor != 100)
                    setAdfr_income_factor(true);
            }        
        
            setADFR_Result(adfr);
            if (actual_adfr == 0) {             
                setADFR_Result2(0);  
                setADFR_lblZero(true);           
            } else{
                setADFR_Result2(adfr)
                setADFR_lblZero(false);

            }

            if (drug_cost > 0) {            
                setlbl_drug_no_item_cost(false);
                setSummary_SubsidyAmount(sf_subsidy_amt)            
            } else {
                setlbl_drug_no_item_cost(true);
                setSummary_SubsidyAmount('')
            }
           

         if (sf_cont_ratio !="") {
             //sf ratio
             if (patient_cont_amt == drug_cost) {
                setlbl_drug_adfr_times_cont(false);
                setlbl_drug_max_cont_amt_or_item_cost(true);
             } else {                
                setlbl_drug_adfr_times_cont(true);
                setlbl_drug_max_cont_amt_or_item_cost(false);
             }

             setTrSFFixAmt(false);
             setTrSFContRatio(true)
             setContRatio_Result(sf_cont_ratio + '%')
            
             var _temp_result = actual_adfr * sf_cont_ratio / 100.0;
            
             if (is_ued)
                 _temp_result = 1000000;
             setADFR_ContRatio_Result(Math.round(_temp_result))
        
         } else {
            //sf fix amt
            setlbl_drug_adfr_times_cont(false)
             if (patient_cont_amt == drug_cost && drug_cost > 0) {    
                setlbl_drug_max_cont_amt_or_item_cost(true);
             } else {
                setlbl_drug_max_cont_amt_or_item_cost(false);
             }
             
             setTrSFContRatio(false);
             setTrSFFixAmt(true);
             setFixAmt_Result(sf_fix_cont_amt);
         }

         if (drug_cost == 0) {        
            setTrEstimateItemCost(false);
         } else {
            setTrEstimateItemCost(true);
         }
         axios({
            method: 'post',
            url: APIHost+'/setDetail',
            data: {
               
                refNo:props.refno,
                general:{                        
                    item_type:"D",
                    item_cost:EstimatedItemCost,
                    is_recurrent_app:recurrentApp,
                    program_type:(_CCFMD=='true')?"CCF-MD" : "SF",                        
                },  
                hhmIncome: householdIncomeAssets.map((item)=>{
                    return {                                    
                            "item_no": item.id,
                            "patient_rel": item.patient_rel,
                            "salary": item.salary,
                            "allowance": item.allowance,
                            "double_pay": item.double_pay,
                            "benefitInKind": item.benefit_in_kind,
                            "pension": item.pension,
                            "rental_income": item.rental_income,
                            "sponsor": item.sponsor,
                            "other_income": item.other_income,                                
                            
                    }}),
                asset:householdIncomeAssets.map((item)=>{
                    return {                                    
                            "item_no": item.id,
                            "patient_rel": item.patient_rel,                                
                            "cash": item.cash,
                            "bank_saving": item.saving,
                            "investment": item.investment,
                            "insurance": item.insurance,
                            "non_owner_property": item.property,
                            "carpark": item.carpark,
                            "land": item.land,
                            "other_assets": item.other,
                    }}),
                assessment:{
                    rent_mortgage: DeductRentMortgage,
                    rates_gov_rent:DeductRatesGovRent,
                    mgt_fee:DeductMgtFee,
                    ttl_exp_fm_ppty:SubTotal1,
                    salaries_tax:DeductSalariesTax,
                    pf_mpf: DeductMPF,
                    child_care_exp: DeductChildCareExp,
                    school_fee: DeductSchoolFee,
                    avg_medical_exp: DeductAvgMedicalExp,
                    maint_paid: DeductMaintPaid,
                    personal_allowance: DeductPersonalAllowance,
                    assessmentDate:moment().format("YYYY-MM-DD HH:mm:ss") ,
                    
                    reco_result:resp.data.result.reco_result_sys??'N',
                    subsidy_amt:sf_subsidy_amt,
                    lumpsum_deduct:0,//Drug ADFR_LumpSumDeduct
                    adfr_amt:0,//Drug ADFR_Result
                    sf_cont_ratio:sf_cont_ratio,//ContRatio_Result
                    sf_fix_cont_amt:sf_fix_cont_amt,//FixAmt_Result
                    sf_patient_cont_amt:ADFR_ContRatio_Result,//ADFR_ContRatio_Result
                    total_cont_amt:0,
                    mmdhi_amt:resp.data.result.mmdhiAmt,
                    income_catg_sys:null,
                    asset_catg_sys:null,
                    mean_test_ratio:0,
                    compare_allowance_flag:null,
                    contribution_flag:null,
                    income_factor:income_factor,
                    discount_factor:0,//???
                    
                    mthly_hh_income:DeductRatesGovRent,
                    mthly_ad:DeductGrandTotal,
                    mthly_disp_capital:SubTotal1,                     
                },   
                deduct:{
                    rent_mortgage:DeductRentMortgage,
                    rates_gov_rent:DeductRatesGovRent,
                    mgt_fee:DeductMgtFee,
                    ttl_exp_fm_ppty:SubTotal1,
                    salaries_tax:DeductSalariesTax,
                    pf_mpf:DeductMPF,
                    child_care_exp:DeductChildCareExp,
                    school_fee:DeductSchoolFee,
                    avg_medical_exp:DeductAvgMedicalExp,
                    maint_paid:DeductMaintPaid,
                    personal_allowance:DeductPersonalAllowance,
                }
            },
           
          }).then((resp)=>{
             console.log(resp.data);
        });   
            setShowLoading(false);
          }).catch((err)=>{
            console.log(err);
            //setShowLoading(false);            
            setDiagTitle("Connection error");            
            setDiagContent(err.toString());
            setOpen(true);            
          })

    }
    const NonDrugAssessment =()=>{
        setlbl_drug_no_item_cost(false);
        setlbl_drug_max_cont_amt_or_item_cost(false);
        setlbl_drug_adfr_times_cont(false)
        setTrEstimateItemCost(true);
        setlbl_tab5_nondrug_income_below_mmdhi(false);
        setlbl_tab5_nondrug_income_equal_mmdhi(false);
        setlbl_tab5_nondrug_income_above_mmdhi(false);
        setlbl_tab5_nondrug_asset_result("");
        
        //GET_NONDRUG_ASSESSMENT
        //input:
        //program_type: _CCFMD?"CCF-MD" : "SF"
        //item_cost:EstimatedItemCost
        //hhm_size:HouseholdMemberCount
        //hh_income:HouseholdIncomeTotal
        //asset:HouseholdAssetTotal
        //output: 
        //result //0: Fail; 1:Success
        //assessment_date
        //item_cost
        //hhm_size
        //hh_income
        //asset
        //income_catg
        //asset_catg
        //reco_result_sys
        //total_cont_ratio
        //total_cont_amt
        //subsidy_amt
        //mean_test_ratio
        //compare_allowance_flag
        //contribution_flag
        axios({
            method: 'post',
            url: APIHost+'/NONDRUGASSESSMENT',
            data: {
                program_type:(_CCFMD=='true')?"CCF-MD" : "SF",
                item_cost:EstimatedItemCost,
                hhm_size:householdMemberCount,
                hh_income:householdIncomeTotal,
                asset:householdAssetTotal,
                refno:props.refno,
            },
           
          }).then((resp)=>{
            console.log(resp.data);
            
            if(resp.data.retCde != '0'){
                if(!isEmpty(resp.data.Message)){
                    var error_msg = resp.data.Message;
                    setDiagContent('Fail Retrieve Data\n' + error_msg);
                    setDiagTitle("");                        
                    setOpen(true);
                    return;
                }
            }

            let mean_test_ratio = resp.data.result.mean_test_ratio;
            let compare_allowance_flag = resp.data.result.compare_allowance_flag;
            let income_catg = resp.data.result.income_catg;
            let asset = resp.data.result.asset;
            let item_cost = resp.data.result.item_cost;
            let total_cont_ratio = resp.data.result.total_cont_ratio;
            let asset_catg = resp.data.result.asset_catg;
            let total_cont_amt = resp.data.result.total_cont_amt;
            let hh_income= householdIncomeTotal;
            let subsidy_amt= resp.data.result.subsidy_amt;
            
            setNonDrugSummary_EstimatedItemCost(convFloat(item_cost));
            setNonDrugSummary_HouseholdIncome(householdIncomeTotal);
            setNonDrugSummary_Asset(convFloat(asset));
            setSummary_PatientAmount(convFloat(total_cont_amt));
            setSummary_SubsidyAmount(convFloat(subsidy_amt))

            var msg_mean_test_ratio = props.i18n.t("tab5_nondrug_income_mean_test_ratio");
            if (mean_test_ratio == '1') {
                msg_mean_test_ratio = '';
            } else {
                msg_mean_test_ratio = msg_mean_test_ratio.replace('{0}', mean_test_ratio);
            }
            
            if (income_catg == 3) {
                setMsg_income_catg(props.i18n.t("tab5_nondrug_income_above_mmdhi").replace('{0}', msg_mean_test_ratio));
                setlbl_tab5_nondrug_income_above_mmdhi(true);                
                setlbl_tab5_nondrug_asset_allowance("");
            } else if (income_catg == 2) {
                setMsg_income_catg(props.i18n.t("tab5_nondrug_income_equal_mmdhi").replace('{0}', msg_mean_test_ratio));
                setlbl_tab5_nondrug_income_equal_mmdhi(true);
    
                if (_CCFMD=='true') {     //  CCF-MD
                    var msg_allowance = "";
                    if (compare_allowance_flag == 'L') {
                        msg_allowance = props.i18n.t("tab5_nondrug_asset_allowance_lower");
                    } else if (compare_allowance_flag == 'U') {
                        msg_allowance = props.i18n.t("tab5_nondrug_asset_allowance_upper");
                    }
                    setlbl_tab5_nondrug_asset_allowance(msg_allowance);
                } else {
                    setlbl_tab5_nondrug_asset_allowance("");
                }
            } 
            else if (income_catg == 1) {
                setMsg_income_catg(props.i18n.t("tab5_nondrug_income_below_mmdhi").replace('{0}', msg_mean_test_ratio));
                setlbl_tab5_nondrug_income_below_mmdhi(true);
    
                if (_CCFMD=='true') {     //  CCF-MD
                    var msg_allowance = "";
                    if (compare_allowance_flag == 'L') {
                        msg_allowance = props.i18n.t("tab5_nondrug_asset_allowance_lower");
                    } else if (compare_allowance_flag == 'U') {
                        msg_allowance = props.i18n.t("tab5_nondrug_asset_allowance_upper");
                    }                    
                    setlbl_tab5_nondrug_asset_allowance(msg_allowance);
                } else {                    
                    setlbl_tab5_nondrug_asset_allowance("");
                }
            }
    
            setlbl_tab5_nondrug_asset_result('N/A')
            if (asset_catg == 1) {
                var msg = props.i18n.t("tab5_nondrug_asset_below_x2");                
                setlbl_tab5_nondrug_asset_result(msg);
            } 
            else if (asset_catg == 2) {
                if (_CCFMD=='true') {     //  CCF-MD
                    var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                    msg = msg.replace('{0}', 2);
                    msg = msg.replace('{1}', 3);                    
                    setlbl_tab5_nondrug_asset_result(msg);
                } else {
                    var factor = asset / item_cost;
                    var upper = '', lower = '';
    
                    if (factor > 2 && factor < 2.25) {
                        upper = 2.25;
                        lower = 2;
    
                        var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                        msg = msg.replace('{0}', lower);
                        msg = msg.replace('{1}', upper);
                        setlbl_tab5_nondrug_asset_result(msg);    
                    } else if (factor >= 2.25 && factor < 2.5) {
                        upper = 2.5;
                        lower = 2.25;
    
                        var msg = props.i18n.t("tab5_nondrug_asset_x225_to_x3");
                        msg = msg.replace('{0}', lower);
                        msg = msg.replace('{1}', upper);
                        setlbl_tab5_nondrug_asset_result(msg);
    
                    } else if (factor >= 2.5 && factor < 2.75) {
                        upper = 2.75;
                        lower = 2.5;
    
                        var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                        msg = msg.replace('{0}', lower);
                        msg = msg.replace('{1}', upper);
                        setlbl_tab5_nondrug_asset_result(msg);
                    } else if (factor >= 2.75 && factor < 3) {
                        upper = 3;
                        lower = 2.75;
    
                        var msg = props.i18n.t("tab5_nondrug_asset_x2_to_x225");
                        msg = msg.replace('{0}', lower);
                        msg = msg.replace('{1}', upper);
                        setlbl_tab5_nondrug_asset_result(msg);
                    } else if (factor == 3) {
                        var msg = props.i18n.t("tab5_nondrug_asset_above_x3");    
                        setlbl_tab5_nondrug_asset_result(msg);
                    }
                }    
             } 
            else if (asset_catg == 3) {
                var msg = props.i18n.t("tab5_nondrug_asset_above_x3"); 
                setlbl_tab5_nondrug_asset_result(msg);
            }
            
            if (_CCFMD=='true') {     //  CCF-MD
                setTableNonDrugSummary2(false);
            } else {
                setTableNonDrugSummary2(true);  
                setNonDrugSummary_EstimatedItemCost2(item_cost);
                setNonDrugSummary_ContRatio(total_cont_ratio);
                setNonDrugSummary_Result(total_cont_amt);
            }
            axios({
                method: 'post',
                url: APIHost+'/setDetail',
                data: {
                   
                    refNo:props.refno,
                    general:{                        
                        item_type:"N",
                        item_cost:EstimatedItemCost,
                        is_recurrent_app:recurrentApp,
                        program_type:(_CCFMD=='true')?"CCF-MD" : "SF",                        
                    },  
                    hhmIncome: householdIncomeAssets.map((item)=>{
                        return {                                    
                                "item_no": item.id,
                                "patient_rel": item.patient_rel,
                                "salary": item.salary,
                                "allowance": item.allowance,
                                "double_pay": item.double_pay,
                                "benefitInKind": item.benefit_in_kind,
                                "pension": item.pension,
                                "rental_income": item.rental_income,
                                "sponsor": item.sponsor,
                                "other_income": item.other_income,                                
                                
                        }}),
                    asset:householdIncomeAssets.map((item)=>{
                        return {                                    
                                "item_no": item.id,
                                "patient_rel": item.patient_rel,                                
                                "cash": item.cash,
                                "bank_saving": item.saving,
                                "investment": item.investment,
                                "insurance": item.insurance,
                                "non_owner_property": item.property,
                                "carpark": item.carpark,
                                "land": item.land,
                                "other_assets": item.other,
                        }}),
                    assessment:{
                        rent_mortgage: DeductRentMortgage,
                        rates_gov_rent:DeductRatesGovRent,
                        mgt_fee:DeductMgtFee,
                        ttl_exp_fm_ppty:SubTotal1,
                        salaries_tax:DeductSalariesTax,
                        pf_mpf: DeductMPF,
                        child_care_exp: DeductChildCareExp,
                        school_fee: DeductSchoolFee,
                        avg_medical_exp: DeductAvgMedicalExp,
                        maint_paid: DeductMaintPaid,
                        personal_allowance: DeductPersonalAllowance,
                        assessmentDate:moment().format("YYYY-MM-DD HH:mm:ss") ,
                        
                        reco_result:resp.data.result.reco_result_sys??'N',
                        subsidy_amt:subsidy_amt,
                        lumpsum_deduct:0,//Drug ADFR_LumpSumDeduct
                        adfr_amt:0,//Drug ADFR_Result
                        sf_cont_ratio:null,//ContRatio_Result
                        sf_fix_cont_amt:null,//FixAmt_Result
                        sf_patient_cont_amt:ADFR_ContRatio_Result,//ADFR_ContRatio_Result
                        total_cont_amt:total_cont_amt,
                        mmdhi_amt:resp.data.result.mmdhiAmt,
                        income_catg_sys:mean_test_ratio,
                        asset_catg_sys:asset_catg,
                        mean_test_ratio:isFinite(mean_test_ratio)?mean_test_ratio:0,
                        compare_allowance_flag:compare_allowance_flag,
                        contribution_flag:resp.data.result.contribution_flag,
                        income_factor:null,
                        discount_factor:0,//???
                        
                        mthly_hh_income:DeductRatesGovRent,
                        mthly_ad:DeductGrandTotal,
                        mthly_disp_capital:SubTotal1,                        
                    },   
                },
               
              }).then((resp)=>{
                 console.log(resp.data);
            });    
            setShowLoading(false); 

          }).catch((err)=>{
            console.log(err);
            //setShowLoading(false);            
            setDiagTitle("Connection error");            
            setDiagContent(err.toString());
            setOpen(true);
          })
       
        
    }

    const [showLoading,setShowLoading] = React.useState(false);

    useEffectOnce(()=>{
        GetPersonalAllowance()        
        setShowLoading(true);
        
        if (isDrug == 'true') {
            DrugAssessment();
        } else {
            NonDrugAssessment();
        } 
    });
    React.useEffect(()=>{
        setPatient_amt_msg(false);        
//        setReviewResultTip(false);  
        if (isDrug == 'true') {
            DrugAssessment();
        } else {
            NonDrugAssessment();
        } 
    },[lang]);

    const AssessmentResultAsBelow1 =()=>{
return isDrug =='true'?<AssessmentResultAsBelow1_1/>:<AssessmentResultAsBelow1_2/>
    }
    const AssessmentResultAsBelow1_1 =()=>{
        return(<>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > {props.i18n.t("tab5_note") } </Typography>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > : </Typography>
            <Typography variant="h5"  sx={{fontWeight: 'bold'}} ><br/></Typography>
            <Grid container alignItems="flex-start">
                <Grid item  xs={4} > <Typography variant="h5">{props.i18n.t("tab5_patient_amt")}</Typography></Grid>
                <Grid item  xs={4}> 
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5"  >$</Typography>
                        <TextField value={Summary_PatientAmount.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4} alignItems="flex-end"  >                  
                    {lbl_drug_adfr_times_cont?<Typography variant="h5" color='purple' >{props.i18n.t("tab5_drug_adfr_times_cont_ratio")}</Typography>:<></>}
                    <Typography variant="h5" color='purple' >
                        {showLoading?<Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />:(patient_amt_msg?props.i18n.t("tab5_drug_ued"):"")}
                    </Typography>
                    {lbl_drug_max_cont_amt_or_item_cost?<Typography variant="h5" color='purple' >{props.i18n.t("tab5_drug_patient_fix_cont_amt_or_item_cost")}</Typography>:<></>}

                </Grid>
            </Grid>
            <Grid container alignItems="center">    
                <Grid item  xs={4}>  <Typography variant="h5" >{props.i18n.t("tab5_subsidy_amt")}</Typography></Grid>
                <Grid item  xs={4}> 
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5"  >$</Typography>
                        <TextField value={Summary_SubsidyAmount.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>                    
                    {lbl_drug_no_item_cost?<Typography variant="body" color='purple' > {props.i18n.t("tab5_drug_no_item_cost")} </Typography>:<></>}
                </Grid>
                <Grid item  xs={12}>                        
                    
                </Grid>
                
            </Grid>  
        
        </>
        );
    }
    const AssessmentResultAsBelow1_2 =()=>{
        return(<>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > {props.i18n.t("tab5_note") } </Typography>
            <Typography variant="h5" sx={{fontWeight: 'bold'}}  display="inline" > : </Typography>
            <Typography variant="h5"  sx={{fontWeight: 'bold'}} ><br/></Typography>
            
                <Grid container alignItems="flex-end">
                    <Grid item  xs={4} > <Typography variant="h5">{props.i18n.t("tab5_patient_amt")}</Typography></Grid>
                    <Grid item  xs={4}> 
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography variant="h5"  >$</Typography>
                            <TextField value={Summary_PatientAmount.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                            variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                        </Stack>
                    </Grid>
                    <Grid item  xs={4} alignItems="flex-end"  >
                
                    </Grid>
                </Grid>
            
            <Grid container alignItems="center">    
            <Grid item  xs={4}>  <Typography variant="h5" >{props.i18n.t("tab5_subsidy_amt")}</Typography></Grid>
            <Grid item  xs={4}> 
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Typography variant="h5"  >$</Typography>
                    <TextField value={Summary_SubsidyAmount.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0})}
                    variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                </Stack>
            </Grid>
            <Grid item  xs={4}>
               
            </Grid>
            <Grid item  xs={12}>                        
                
            </Grid>
            
        </Grid>  
        
        </>
        );
    }
    const AssessmentResultAsBelow2 =()=>{
        return isDrug=='true'?<AssessmentResultAsBelow2_1/>:<AssessmentResultAsBelow2_2/>
    }
    const AssessmentResultAsBelow2_1 =()=>{
        return(<>
            <Grid container alignItems="flex-end">
                <Grid item  xs={4}> 
                    <Typography variant="h5"  sx={{fontWeight: 'bold'}} paragraph > {props.i18n.t("tab5_assessment_result_as_below") } :</Typography>
                </Grid>
                <Grid item  xs={8}>
                
                </Grid>
                
                {trEstimateItemCost&&
                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_drug_estimatedcost") } </Typography>
                </Grid>}
                {trEstimateItemCost&&
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6"  >$</Typography>
                        <TextField value={DrugSummary_EstimatedItemCost.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0})}
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>                
                }
                {trEstimateItemCost&&
                <Grid item  xs={4}>
                </Grid>
                }
                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_drug_householdincome") } </Typography>
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={DrugSummary_HouseholdIncome.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>
                </Grid>
                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_drug_expense") } </Typography>                   
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={DrugSummary_Expenditure.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0})}
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>
                </Grid>
                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_drug_asset") } </Typography>                   
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={DrugSummary_Asset.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0})  }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>
                </Grid>
                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_drug_deduct") } <IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_formula_deduct_tooltip_title","tab5_drug_formula_deduct_tooltip_desc")}} ><img width="18px" src="images/question_mark.png"/></IconButton> </Typography>                   
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6">$</Typography>
                        <TextField value={DrugSummary_LumpSumDeduct.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>
                </Grid>
            </Grid>
        </>);
    }

    const AssessmentResultAsBelow2_2 =()=>{//nonDrug
        
        
        return(<>
            <Grid container >    
                <Grid item  xs={8}> 
                    <Typography variant="h5"  sx={{fontWeight: 'bold'}} paragraph > {props.i18n.t("tab5_assessment_result_as_below") } :</Typography>
                </Grid>
                <Grid item  xs={4}>
                </Grid>

                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_nondrug_estimatedcost") } </Typography>
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={NonDrugSummary_EstimatedItemCost.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}> 
                    
                </Grid>

                <Grid item  xs={4} alignItems="flex-start"> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_nondrug_householdincome") } </Typography>                   
                </Grid>
                <Grid item  xs={4} alignItems="flex-start">
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={NonDrugSummary_HouseholdIncome.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}>                 
                    
                    {lbl_tab5_nondrug_income_below_mmdhi&&<Typography variant="h5" color='purple'  > {/*props.i18n.t("tab5_nondrug_income_below_mmdhi").replace('{0}', msg_mean_test_ratio)*/msg_income_catg } </Typography>}                   
                    {lbl_tab5_nondrug_income_equal_mmdhi&&<Typography variant="h5" color='purple'  > {/*props.i18n.t("tab5_nondrug_income_equal_mmdhi").replace('{0}', msg_mean_test_ratio)*/msg_income_catg } </Typography>}                                       
                    {lbl_tab5_nondrug_income_above_mmdhi&&<Typography variant="h5" color='purple'  > {/*props.i18n.t("tab5_nondrug_income_above_mmdhi").replace('{0}', msg_mean_test_ratio) */msg_income_catg} </Typography>}                                       
                </Grid>

                <Grid item  xs={4}> 
                    <Typography variant="h5" paragraph > {props.i18n.t("tab5_nondrug_asset") }: </Typography>
                </Grid>
                <Grid item  xs={4}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" >$</Typography>
                        <TextField value={NonDrugSummary_Asset.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={4}> 
                    <Typography variant="h5" color='purple' >{lbl_tab5_nondrug_asset_result}</Typography>
                    <Typography variant="h5" color='purple' >{lbl_tab5_nondrug_asset_allowance}</Typography>
                </Grid>

                <Grid item  xs={16}> 
                    <Typography variant="h5"  paragraph > <br/> </Typography>
                </Grid>
            </Grid>
            {tableNonDrugSummary2&&
                <Grid container >    
                    <Grid item  xs={4}> 
                        <Typography variant="h5"  noWrap sx={{mb:4}}> {props.i18n.t("tab5_patient_amt") }: </Typography>
                        <br/> 
                    </Grid>
                    <Grid item  xs={8}> 
                    </Grid>
                    
                    <Grid item  xs={4}> 
                        <Stack direction="row" alignItems="center" justifyContent="center" >
                            <Typography variant="h5"> {props.i18n.t("tab5_nondrug_estimatedcost") } </Typography> 
                        </Stack>
                    </Grid>
                    <Grid item  xs={4}> 
                        <Stack direction="row" alignItems="center" justifyContent="center" ><Typography variant="h5" > {props.i18n.t("tab5_drug_patient_cont_ratio") } <IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_patient_cont_ratio_tooltip_title","tab5_nondrug_cont_remark")}} ><img width="18px" src="images/question_mark.png"/></IconButton>   </Typography></Stack>                    
                    </Grid>
                    <Grid item  xs={4}> 
                    </Grid>

                    <Grid item  xs={4}>
                        <Stack direction="row" alignItems="center" justifyContent="center" >
                            <Typography variant="h6">$</Typography>
                            <TextField value={isFinite(NonDrugSummary_EstimatedItemCost2)? parseFloat(NonDrugSummary_EstimatedItemCost2).toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}):NonDrugSummary_EstimatedItemCost2 }
                            variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} ,width:"80%"}} />
                        </Stack>
                    </Grid>
                    <Grid item  xs={4}>
                        
                        <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h6" noWrap  >X </Typography>    
                            <TextField value={NonDrugSummary_ContRatio }
                            variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'},width:"70%" }} />
                        <Typography variant="h5" noWrap  > % = </Typography>
                        </Stack>
                    </Grid>
                    <Grid item  xs={4}>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="h6" >$</Typography>
                            <TextField value={isFinite(NonDrugSummary_Result)?parseFloat(NonDrugSummary_Result).toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}):NonDrugSummary_Result }
                            variant="outlined"  align="rignt" sx={{p:1, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'},width:"70%" }} />
                        </Stack>
                    </Grid>
                    <Grid item  xs={3}> 
                    </Grid>
                </Grid> 
            }    
            <Typography variant="h3" color='purple' >&nbsp;</Typography>
        </>);
    }

    const CalcResult=()=>{
        return isDrug=='true'?<CalcResult_1/>:<CalcResult_2/>
    }
    const CalcResult_1=()=>{
        return(<>        
            <Typography variant="h5" paragraph sx={{mt:4}}><br/> {props.i18n.t("tab5_drug_formula_adfr") } {drug_formula_adfr?<IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_formula_adfr_tooltip_title","tab5_drug_formula_adfr_tooltip_desc")}} ><img width="18px" src="images/question_mark.png"/></IconButton>:<></>} </Typography>  
            <Typography variant="h3" color='purple' >&nbsp;</Typography>

            <Grid container  alignItems="flex-end" >    
                <Grid item  xs={2} >
                    <Typography variant="h5" paragraph align="center" sx={{ml:2}} > {props.i18n.t("tab5_drug_formula_householdincome") } </Typography>  
                </Grid>
                <Grid item  xs={3}>
                    <Typography variant="h5" paragraph align="center" sx={{ml:4}}> {props.i18n.t("tab5_drug_formula_expense") } </Typography>
                </Grid>
                <Grid item  xs={2}>
                    <Typography variant="h5" paragraph align="center" sx={{mr:2,width:'14rem'}} > <span style={{wordWrap : 'normal'}} dangerouslySetInnerHTML={{__html:props.i18n.t("tab5_drug_formula_asset")}}/></Typography>  
                </Grid>
                <Grid item  xs={3}>
                    <Stack direction='row' alignItems="flex-start" sx={{ml:10}}><Typography variant="h5" paragraph  align="center"> {props.i18n.t("tab5_drug_formula_deduct") } </Typography>      </Stack>
                </Grid>
                <Grid item  xs={2}>
                    <Typography variant="h5" paragraph align="center"> {props.i18n.t("tab5_drug_formula_adfr") } </Typography>                 
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" >    
                <Grid item  xs={2} >
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5" >($</Typography>
                        <TextField value={ADFR_HouseholdIncome.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{px:1,py:0, input: {py:0.1,width:'8rem',fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                    </Stack>
                </Grid>
                <Grid item  xs={3}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        
                        <Typography variant="h5" >-$</Typography>
                        {<TextField value={ADFR_Expenditure.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                            variant="outlined"  align="rignt" 
                            sx={{px:1,py:0, width:adfr_income_factor==true?"8rem":"10rem", input: {width:'10rem',py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />}
                        
                        <Typography variant="h5" >)x</Typography>
                        {adfr_income_factor==true?<Typography variant="h5" >{ADFR_IncomeFactor}% x</Typography>:<></>}
                        
                    </Stack>
                </Grid>
                <Grid item  xs={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5" >12+</Typography>
                        <Typography variant="h5" >($</Typography>
                        <TextField value={ADFR_Asset.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{width:200,px:1,py:0, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200'} }} />
                        <Typography variant="h5" >-</Typography>
                    </Stack>
                </Grid>
                <Grid item  xs={3}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5" >$</Typography>
                        <TextField value={ADFR_LumpSumDeduct.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                        variant="outlined"  align="rignt" sx={{width:'10rem',px:1,py:0, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'10rem'} }} />
                        <Typography variant="h5" >) x 50%</Typography>   
                    </Stack>
                </Grid>
                <Grid item  xs={2}>                
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Typography variant="h5" >=$</Typography>
                        
                        <TextField value={ADFR_Result.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                                        variant="outlined"  align="rignt" sx={{width:'12rem',px:1,py:0, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'12rem'} }} />
                        {ADFR_lblZero?<Typography variant="h5" >($0)</Typography>:<></>  }
                    </Stack>
                </Grid>
            </Grid>
            {/*tablePatientCont*/}
            {trSFContRatio?
                <Grid container  alignItems="flex-end" sx={{pt:4}}  >    
                    <Grid item  xs={2} >
                        <Typography variant="h5" paragraph align="center" sx={{ml:2}} > {props.i18n.t("tab5_drug_formula_adfr") } </Typography>  
                    </Grid>
                    <Grid item  xs={2}>
                        <Typography variant="h5" paragraph align="center" sx={{ml:6}}> {props.i18n.t("tab5_drug_patient_cont_ratio") }<IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_patient_cont_ratio_tooltip_title","tab5_drug_patient_cont_ratio_tooltip_desc")}} ><img width="18px" src="images/question_mark.png"/></IconButton> </Typography>
                    </Grid>
                    <Grid item  xs={3}>
                        <Typography variant="h5" paragraph align="center" sx={{ml:6}}> {props.i18n.t("tab5_drug_patient_fix_cont_amt") }<IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_patient_fix_cont_amt","tab5_drug_ued")}} ><img width="18px" src="images/question_mark.png"/></IconButton> </Typography>
                    </Grid>
                    <Grid item  xs={5}>
                        
                    </Grid>
                
                    <Grid item  xs={2} >
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography variant="h5" >$</Typography>
                            <TextField value={ADFR_Result.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                            variant="outlined"  align="rignt" sx={{px:1,py:0, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'12rem'} }} />                          
                        </Stack>
                    </Grid>
                    <Grid item  xs={2}>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography variant="h5" >X</Typography>
                            <TextField value={ContRatio_Result}
                            variant="outlined"  align="rignt" sx={{px:1,py:0,ml:2, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'14rem'} }} />                                                  
                        </Stack>
                    </Grid>
                    <Grid item  xs={3}>                
                    <Stack direction="row" alignItems="center" justifyContent="center">
                            <Typography variant="h5" >=$</Typography>
                            <TextField value={ADFR_ContRatio_Result.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                            variant="outlined"  align="rignt" sx={{px:1,py:0,ml:2, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'14rem'} }} />                                                  
                        </Stack>
                    </Grid>
                    <Grid item  xs={5}>
                        
                    </Grid>
                </Grid>
            :<></>}
            {trSFFixAmt?
                <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{py:'2rem'}}>
                    <Typography variant="h5" paragraph sx={{mt:2}}> {props.i18n.t("tab5_drug_patient_fix_cont_amt") } <IconButton size="large" sx={{p:0.5}}  onClick={()=>{handleClickOpen("tab5_drug_patient_fix_cont_tooltip_title","tab5_drug_patient_fix_cont_tooltip_desc")}} ><img width="18px" src="images/question_mark.png"/></IconButton><></>=$</Typography>                      
                    <TextField value={FixAmt_Result.toLocaleString('en-US',{style:"decimal",minimumFractionDigits: 0}) }
                    variant="outlined"  align="rignt" sx={{px:1,py:0, input: {py:0.1,fontSize:"1.5rem",textAlign: "right",backgroundColor:'grey.200',width:'18rem'} }} />
                </Stack>
            :<></>}
            
        </>);
    }
    const CalcResult_2=()=>{
        return(<></>)
    }
    return(
        <>
            <Box sx={{ p:2,m:2,border:1, borderRadius:1 }} >
            <ECalApplicationFormBodyHeadDesktop  isDrug={isDrug} onChange={props.onChange}  ecalcState={props.ecalcState} stepVal={props.stepVal} enabledStep={props.enabledStep}  i18n={props.i18n}/>
    
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
                <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} 
                onClick={()=>{ 
                    if(isDrug =='true'){                        
                        props.printPreview({                            
                            total_cont_amt:Summary_PatientAmount,
                            drug_adfr_times_cont_ratio:lbl_drug_adfr_times_cont,
                            showDrug_no_item_cost:lbl_drug_no_item_cost,
                            Summary_SubsidyAmount:Summary_SubsidyAmount,
                            LumpSumDeduct:DrugSummary_LumpSumDeduct,
                            ADFR_Result:ADFR_Result,
                            ADFR_ContRatio_Result:ADFR_ContRatio_Result,
                            Actual_lumpsum_deduct: ADFR_LumpSumDeduct,
                            income_factor:ADFR_IncomeFactor,
                            showPart2:trSFFixAmt,
                            sf_fix_cont_amt:FixAmt_Result,
                            sf_cont_ratio:ContRatio_Result,
                        });    
                    }else                        
                      props.printPreview({
                        total_cont_amt:Summary_PatientAmount,
                        msg_income_catg:msg_income_catg,
                        asset_catg_sys:lbl_tab5_nondrug_asset_result,
                        compare_allowance_flag:lbl_tab5_nondrug_asset_allowance,
                        total_cont_ratio:NonDrugSummary_ContRatio,
                        Summary_SubsidyAmount:Summary_SubsidyAmount,
                        showPart2:trSFFixAmt,
                        sf_fix_cont_amt:FixAmt_Result,
                        sf_cont_ratio:ContRatio_Result,
                    });              
                    }} >  {props.i18n.t("btnPreview")}</Button>
            </Stack> 
            
            <AssessmentResultAsBelow1/>
            <Typography variant="h3" color='purple' >&nbsp;</Typography>
            <AssessmentResultAsBelow2/>
            <CalcResult/>            
            
            <Typography variant="h3" color='purple' >&nbsp;</Typography>
            <Box >
                <Box  sx={{border: 2,backgroundColor: 'grey.100'}}>
                <Grid container  spacing={2}> 
                    <Grid item xs={12} justifyContent="flex-end" sx={{ mt:1}} > <Typography  variant="h4" align="center" >{props.i18n.t("tab5_disclaimer_title")}</Typography></Grid> 
                    <Grid item xs={12} sx={{ mx:1}} >                         
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h6" align="left"><span dangerouslySetInnerHTML={{__html:props.i18n.t("tab5_disclaimer_li1")}}/> </Typography></Stack>          
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h6" align="left">{props.i18n.t("tab5_disclaimer_li2")}</Typography></Stack>
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h6" align="left">{props.i18n.t("tab5_disclaimer_li3")}</Typography></Stack>
                            <Stack direction="row" gap={1} sx={{ mx:2}}><Typography  paragraph variant="h6" align="left">{props.i18n.t("tab5_disclaimer_li4")}</Typography></Stack>                        
                    </Grid>                    
                    <Grid item xs={12} sx={{ m:1}}  > </Grid>
                </Grid>
                </Box>
            </Box> 
            <Grid container spacing={2} >
                <Grid item xs={12} />
                <Grid item xs={3} >
                    <Button variant="outlined" onClick={()=>{isDrug=="true"?props.onChange("monthlyDeduct"):props.onChange("asset")}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>上一步</Button>
                </Grid>
                <Grid item xs={9} ></Grid>
            </Grid>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
                <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
    
  }
  export {ECalApplicationFormBodyResult as ECalApplicationFormBodyResultDesktop}