import * as React from 'react';
import {Stack,Typography, Grid, Box, Button, FormControlLabel,RadioGroup,Radio,IconButton,  Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle, 
  TextField} from '@mui/material';
import {ECalApplicationFormBodyHeadDesktop,convFloat} from './ECalApplicationFormCommon.js';
import { useDispatch,useSelector } from 'react-redux'
import { setState,setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,
  setInit,  
  } from '../store/eCalcStore.js';
import {useEffectOnce} from '../common/common-lib.js';
import moment from 'moment';
import axios from 'axios';
const APIHost = window.APIHost;


function ECalApplicationFormBodyInfoDesktop(props){

  let householdAssetTotal = props.ecalcState.householdAssetTotal;  
  let householdMemberCount = props.ecalcState.householdMemberCount;

  let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
  let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
  let DeductTotal = props.ecalcState.DeductTotal;
  let DeductPersonalAllowance  = props.ecalcState.DeductPersonalAllowance;

  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [allReset,setAllReset] = React.useState(false);

  const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab3_note_tooltip_title") );
  const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab3_note_tooltip_desc"));
  
  //const lang = useSelector((state)=> state.config.lang);

  const [EstimatedItemCost,setEstimatedItemCost] = React.useState(props.ecalcState.EstimatedItemCost);
  const [isDrug,setIsDrug] = React.useState(props.ecalcState.isDrug);
    
  
  const [recurrentApp,setRecurrentApp] = React.useState("false");
  const [_CCFMD,set_CCFMD] = React.useState(props.ecalcState._CCFMD);
  const initHide = useSelector((state)=>state.ecalc.Init??true);
  const initHide2 = useSelector((state)=>state.ecalc.Init2??true);

  const [Summary_PatientAmount,setSummary_PatientAmount] = React.useState(0);
  const [lbl_drug_adfr_times_cont,setlbl_drug_adfr_times_cont] = React.useState(false);
  const [lbl_drug_no_item_cost,setlbl_drug_no_item_cost] = React.useState(false);
  const [Summary_SubsidyAmount,setSummary_SubsidyAmount] = React.useState(0);
  const [DrugSummary_LumpSumDeduct,setDrugSummary_LumpSumDeduct]=React.useState(0);
  const [ADFR_Result,setADFR_Result] = React.useState(0);
  const [ADFR_ContRatio_Result,setADFR_ContRatio_Result]=React.useState("");
  const [ADFR_LumpSumDeduct,setADFR_LumpSumDeduct]=React.useState(0);
  const [ADFR_IncomeFactor,setADFR_IncomeFactor] = React.useState(80);
  const [trSFFixAmt,setTrSFFixAmt]=React.useState(false);
  const [FixAmt_Result,setFixAmt_Result]=React.useState("");
  const [ContRatio_Result,setContRatio_Result]=React.useState("");
  
  const [msg_income_catg,setMsg_income_catg]=React.useState("");
  const [lbl_tab5_nondrug_asset_result,setlbl_tab5_nondrug_asset_result]=React.useState("");
  const [lbl_tab5_nondrug_asset_allowance,setlbl_tab5_nondrug_asset_allowance]=React.useState("");
  const [NonDrugSummary_ContRatio,setNonDrugSummary_ContRatio]=React.useState(0);

  const handleClose2 = () => {
    setOpen2(false);
  };

const handleOk2=()=>{
    setOpen2(false);
    
    setEstimatedItemCost(0);
    dispatch(setSubTotal2(0));
                  
    props.onNext1();
    if(allReset){ props.onReset();}else{
      let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});    
      dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost:0,householdIncomeTotal:0,householdAssetTotal:0,householdMemberCount:1,
        householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance:0,
      }));
      // dispatch(setDeductRentMortgage(0));
      // dispatch(setDeductRatesGovRent(0));
      // dispatch(setDeductMgtFee(0));
      // dispatch(setDeductSalariesTax(0));
      // dispatch(setDeductMPF(0));
      // dispatch(setDeductChildCareExp(0));
      // dispatch(setDeductSchoolFee(0));
      // dispatch(setDeductAvgMedicalExp(0));
      // dispatch(setDeductMaintPaid(0));
      //dispatch(setDeductPersonalAllowance(0));
    }
  }
const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
    setDiagTitle(props.i18n.t(helpTipsTitleKey));
    setDiagContent(props.i18n.t(helpTipsKey));
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
};

const GetPersonalAllowance= ()=>{
  //input: hhm_size//HouseholdMemberCount
  //output: result//0: Fail; 1:Success
  //assessment_date//2023-11-07
  //hhm_size
  // personal_allowance
  axios({
    method: 'post',
    url: APIHost+'/getPersonalAllowance',
    data: {
      "hhm_size":householdMemberCount,
      "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),      
    },
   
  }).then((resp)=>{
    // console.log(resp.data);
    // dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
    //         DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));

    dispatch(setDeductPersonalAllowance(convFloat(resp.data.result.personal_allowance)));
        
    props.calculateDeduct(()=>{
      setDiagTitle("");
      setDiagContent(props.i18n.t('msg_currency_out_of_range'));
      setOpen(true);   
    });            
  }).catch((err)=>{
    console.log(err);
    setDiagTitle("Connection error");            
    setDiagContent(err.toString());
    setOpen(true);
  })
}
useEffectOnce(()=>{    
  GetPersonalAllowance();
});

  const Info1=(props)=>{
    return (    
      <> 
      {initHide?<></>:
        <Stack  direction="row">      
            <Typography paragraph variant="h5" sx={{pt:2,pr:2}}> {props.i18n.t("tab1_lblRecurrent")}* </Typography>
    
            <RadioGroup row value={ initHide2?null:recurrentApp}  onChange={(ev)=>{ 
              
              setRecurrentApp(ev.target.value);
              dispatch(setState({isDrug,_CCFMD,recurrentApp:ev.target.value,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets, DeductTotal,
                DeductPersonalAllowance,Init2:false
              }));     
            
              }}  >
              <FormControlLabel value="true" control={<Radio />} label={<Typography  variant="h5"  display="inline">{props.i18n.t("tab1_label_radRecurrentYes")}</Typography> } />
              <FormControlLabel value="false" control={<Radio />} label={<Typography variant="h5"  display="inline">{props.i18n.t("tab1_label_radRecurrentNo")}</Typography> } />
            </RadioGroup>  
        </Stack> 
      }        
        <Typography  paragraph ><br/> </Typography>
        <Box  sx={{border: 1, px:2 , backgroundColor: 'grey.100'}} >
          <Stack>
            <Typography  paragraph > </Typography>
            <Typography  component={'span'} variant="h5"  align="center" sx={{textDecoration: 'underline'}}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_title")}}/></Typography>
          </Stack> 
          <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>

          <Stack direction="row" ><Typography  variant="h5"> 1.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li1")} </Typography> <Typography variant="h5" >: </Typography></Stack>
          <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li1_1")}}/></Typography></Stack>
          <Typography variant="h5" ><br/></Typography>
          
          <Stack direction="row" ><Typography  variant="h5"> 2.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li2")} </Typography> <Typography variant="h5" >: </Typography></Stack>
          <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li2_1")}}/></Typography></Stack>
          <Typography variant="h5" ><br/></Typography>

          <Stack direction="row" ><Typography  variant="h5"> 3.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li3")} </Typography> <Typography variant="h5" >: </Typography></Stack>
          <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li3_1")}}/></Typography></Stack>
          <Typography variant="h5" ><br/></Typography>

          <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p2_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
          <Typography variant="h5" paragraph> {props.i18n.t("tab1_instruct_p2_1")}</Typography>
          <Typography variant="h5" paragraph> {props.i18n.t("tab1_instruct_p2_2")}</Typography>
          <Typography variant="h5" ><br/></Typography>

          <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p3_drug")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
          <Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_li")}}/></Typography>
          <Typography variant="h5" ><br/></Typography>

          <Stack direction="row" ><Typography variant="h5" sx={{px:0.5}} >^</Typography><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_remarks_ued")}}/></Typography></Stack>
          <Stack direction="row" ><Typography variant="h5" sx={{px:0.5}}>*</Typography><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_drug_remarks")}}/></Typography></Stack>
          
          <Typography variant="h5" ><br/></Typography>
        </Box>    
    </>
    
    )
  }

  const Info2=(props)=>{
    return (    
    <>  
    {initHide?<></>:
    <Stack  direction="row"> 
    <Typography paragraph variant="h5" sx={{pt:2,pr:2}} > {props.i18n.t("tab1_lblCCFMD")}* </Typography>
          <RadioGroup row value={ initHide2?null:_CCFMD}  onChange={(ev)=>{ 
              
              set_CCFMD(ev.target.value)
              dispatch(setState({isDrug,_CCFMD:ev.target.value,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets, DeductTotal,
                DeductPersonalAllowance,Init2:false
              }));
              
            }}  >
            <FormControlLabel value="true" control={<Radio />} label={<Typography component={'span'} variant="h5"  display="inline">{props.i18n.t("tab1_label_radRecurrentYes")}</Typography> } />
            <FormControlLabel value="false" control={<Radio />} label={<Typography component={'span'} variant="h5"  display="inline">{props.i18n.t("tab1_label_radRecurrentNo")}</Typography> } />
          </RadioGroup>    
      </Stack>        
    }    
    
      <Typography  paragraph ><br/> </Typography>
      <Box  sx={{border: 1, px:2 , backgroundColor: 'grey.100'}} >
        <Stack>
          <Typography  paragraph > </Typography>
          <Typography  component={'span'} variant="h5"  align="center" sx={{textDecoration: 'underline'}}> <div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_title_2")}}/></Typography>
        </Stack> 
        <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p1_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>

        <Stack direction="row" ><Typography  variant="h5"> 1.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li1")} </Typography> <Typography variant="h5" >: </Typography></Stack>
        <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li1_2")}}/></Typography></Stack>
        <Typography variant="h5" ><br/></Typography>
         
        <Stack direction="row" ><Typography  variant="h5"> 2.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li2")} </Typography> <Typography variant="h5" >: </Typography></Stack>
        <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li2_1")}}/></Typography></Stack>
        <Typography variant="h5" ><br/></Typography>

        <Stack direction="row" ><Typography  variant="h5"> 3.</Typography><Typography paragraph variant="h5" > {props.i18n.t("tab1_instruct_p1_li3")} </Typography> <Typography variant="h5" >: </Typography></Stack>
        <Stack direction="row" sx={{px:2}} ><Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p1_li3_1")}}/></Typography></Stack>
        <Typography variant="h5" ><br/></Typography>

        <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p2_title")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
        <Typography variant="h5" paragraph> {props.i18n.t("tab1_instruct_p2_1")}</Typography>
        <Typography variant="h5" paragraph> {props.i18n.t("tab1_instruct_p2_2")}</Typography>
        <Typography variant="h5" ><br/></Typography>

        <Stack direction="row"><Typography paragraph variant="h5" sx={{textDecoration: 'underline',}}> {props.i18n.t("tab1_instruct_p3_nondrug")}  </Typography> <Typography variant="h5" >: </Typography></Stack>
        <Typography variant="h5"  component={'span'}><div dangerouslySetInnerHTML={{__html:props.i18n.t("tab1_instruct_p3_nondrug_li")}}/></Typography>
        <Typography variant="h5" ><br/></Typography>
        
        <Typography variant="h5" ><br/></Typography>
      </Box>
    </>
    )
  }
  const ShowInfo=(props)=>{    
    
    return isDrug=="true"?<Info1 i18n={props.i18n}/>:<Info2 i18n={props.i18n}/>
  }
  const curTextConv=(textVal)=>{
    if(textVal === 0) return '$0'
    if(isNaN(textVal))return '$0'
    return textVal
  }
  return(
    
    <Box   sx={{ p:2,m:2,border:1, borderRadius:1 }} >
      <ECalApplicationFormBodyHeadDesktop  isDrug={isDrug} onChange={ (newVal)=>{
        if(initHide){
          setDiagTitle("");
          setDiagContent(props.i18n.t('msg_missingsubsidytype'));
          setOpen(true); 
        }else{
          if(EstimatedItemCost <=0){
            setDiagTitle("");
            setDiagContent(props.i18n.t('msg_missingitemcost'));
            setOpen(true);  
          }else{       
            if(initHide2){
              setDiagTitle("");
              setDiagContent(props.i18n.t(isDrug=="true"?'msg_missingrecurrentapp':'msg_missingsubsidytype'));
              setOpen(true);                
            } else{
              props.onChange(newVal);
            }        
            
          }
        }
       
    }} ecalcState={props.ecalcState} stepVal={props.stepVal} enabledStep={props.enabledStep} i18n={props.i18n}/>
    <Grid container spacing={2} sx={{ py:2 }}>
  
      
    </Grid>
    <Grid container spacing={2} sx={{ py:2 }}>
      <Grid item xs={3} >
        <Typography  variant="h5" align="left"  display="inline" paragraph>{props.i18n.t("tab1_subsidy_type")}?</Typography>
        <br/>
        <Typography  variant="h5" align="left"  display="inline">{props.i18n.t("tab1_lblEstimatedItemCost")}:</Typography>
      </Grid>
      <Grid item xs={5}  align="center" justifyContent="center">
          <Stack > 
            <RadioGroup row value={ initHide?null: isDrug} onClick={()=>{
              dispatch(setInit(false));
            }} onChange={(ev)=>{ 
                
                setIsDrug(ev.target.value);
                //dispatch(setState({isDrug:ev.target.value,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                props.onNext1();
                if(ev.target.value == "true") {                
                  GetPersonalAllowance();
                }
                let _householdIncomeAssets = householdIncomeAssets.slice(0,1).map((item) => {return { ...item, salary:0,allowance:0,double_pay:0,benefit_in_kind:0, pension:0,rental_income:0,sponsor:0,other_income:0,subTotal:0};});    
                dispatch(setState({isDrug:ev.target.value,_CCFMD,recurrentApp,EstimatedItemCost:0,householdIncomeTotal:0,householdAssetTotal:0,householdMemberCount:1,
                  householdIncomeAssets:_householdIncomeAssets,DeductTotal:0,DeductPersonalAllowance:0,
                }));
                setEstimatedItemCost(0);
                dispatch(setSubTotal2(0));
                dispatch(setDeductRentMortgage(0));
                dispatch(setDeductRatesGovRent(0));
                dispatch(setDeductMgtFee(0));
                dispatch(setDeductSalariesTax(0));
                dispatch(setDeductMPF(0));
                dispatch(setDeductChildCareExp(0));
                dispatch(setDeductSchoolFee(0));
                dispatch(setDeductAvgMedicalExp(0));
                dispatch(setDeductMaintPaid(0));
                dispatch(setDeductPersonalAllowance(0));
                dispatch(setInit(false));
              }}  >
              <FormControlLabel value="true" control={<Radio />} label={<Typography variant="h5" component={'span'} display="inline">{props.i18n.t("tab1_label_radDrugItem")}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{handleClickOpen("tab1_radDrugItem_tooltip_title","tab1_radDrugItem_tooltip_desc") }} ><img src="images/question_mark.png" width="18px"/></IconButton></Typography> } />
              <FormControlLabel value="false"  control={<Radio />} label={<Typography variant="h5"component={'span'}  display="inline">{props.i18n.t("tab1_label_radNonDrugItem")}<IconButton  size="large" sx={{p:0.5}} onClick={()=>{handleClickOpen("tab1_radNonDrugItem_tooltip_title","tab1_radNonDrugItem_tooltip_desc") }} ><img src="images/question_mark.png" width="18px"/></IconButton></Typography> } />
            </RadioGroup>  
            <TextField value={ curTextConv(EstimatedItemCost)} onChange={(ev)=>{ 
                if(isFinite(parseFloat(ev.target.value))){
                  if(parseFloat(ev.target.value)<=999999999){
                    setEstimatedItemCost(parseFloat(ev.target.value)); 
                    if(ev.nativeEvent.inputType == "deleteContentBackward"||
                        ev.nativeEvent.inputType == "deleteContentForward")
                        if(parseFloat(ev.target.value)<10)
                          setEstimatedItemCost(0);
    
                      dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost:ev.target.value,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                  
                  }
                  
                }
                  
              }} 
              onBlur={(ev)=>{                 
                if(isFinite(parseFloat(ev.target.value))) {
                  setEstimatedItemCost(parseFloat(ev.target.value));
                  dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost:ev.target.value,householdIncomeTotal,householdAssetTotal,householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
                }else{
                  setEstimatedItemCost(0);
                }                  
              }} variant="outlined"  
              sx={{ width:"22rem", input: {textAlign: "right",backgroundColor:'#FFF',fontSize:"1.5rem"}}}
              inputProps={{sx:{py:.35}}}
              align="center" 
              />
          </Stack>
      </Grid>
      <Grid item xs={4} >
        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
          <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{                                          
                        props.printPreviewPre({});                      
                      }} >  {props.i18n.t("btnPreview")}</Button>
          
          <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
              /*confirm clear popup, initial */ 
              setDiagContent(props.i18n.t("msg_confirm_clear_all"));
              setDiagTitle(props.i18n.t("tab3_header"));
              setAllReset(true);
              setOpen2(true);
              }}>{props.i18n.t("btnClearAll")}</Button>
        </Stack> 
        <Typography variant="h5" textAlign="right" paragraph> *{props.i18n.t("required_field")}</Typography>
      </Grid>
    </Grid>
    <ShowInfo i18n={props.i18n}/>
    
    <Typography  paragraph > </Typography>
    <Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{
        if(initHide){
          setDiagTitle("");
          setDiagContent(props.i18n.t('msg_missingsubsidytype'));
          setOpen(true); 
        }else{
          if(EstimatedItemCost <=0){
            if(isDrug=="true"){
              if(initHide2){
                console.log("isdrug:"+isDrug);
                setDiagTitle("");
                setDiagContent(props.i18n.t(isDrug=="true"?'msg_missingrecurrentapp':'msg_missingsubsidytype'));
                setOpen(true);                
              } else{
                props.onChange("income");
                  props.onNext();
              }
            }else{
              setDiagTitle("");
              setDiagContent(props.i18n.t('msg_missingitemcost'));
              setOpen(true);  
            }
            

            
          }else{
                   
              if(initHide2){
                console.log("isdrug:"+isDrug);
                setDiagTitle("");
                setDiagContent(props.i18n.t(isDrug=="true"?'msg_missingrecurrentapp':'msg_missingsubsidytype'));
                setOpen(true);                
              } else{
                props.onChange("income");
                props.onNext();
              }   
          }
        }
      }}  sx={{fontSize: 22,backgroundColor:'grey.200',}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack>
     
      <Dialog
          open={open}
          onClose={handleClose}
          fullwidth 
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >     
              <div dangerouslySetInnerHTML={{__html:diagContent}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose} autoFocus>
            {props.i18n.t("tooltip_close")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
     
    </Box>
    
  )
}

export {ECalApplicationFormBodyInfoDesktop}