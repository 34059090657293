import {throttle} from 'lodash';
import { createSlice,configureStore } from '@reduxjs/toolkit';
const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
  };

  
  const eCalcStateSlice = createSlice({
    name: 'eCalcState',
    initialState: {
      config:{lang:'tc'},
      ecalc:{

      }
    },
    reducers: {
        setState: (state,action) => {
          let payload = action.payload;
            state.ecalc = {...state.ecalc,...payload};
          }, 
        setLang: (state,action) => {
            state.config.lang = action.payload;
          },
        setSubTotal1:(state,action) => {
          state.ecalc.SubTotal1 = action.payload;
        },
        setSubTotal2:(state,action) => {
          state.ecalc.SubTotal2 = action.payload;
        },
        setDeductGrandTotal:(state,action) => {    
          state.ecalc.DeductGrandTotal = action.payload;
        },
        setDeductTotal:(state,action) => {          
          state.ecalc.DeductTotal = action.payload;
        },
        setDeductRentMortgage:(state,action) => {
          state.ecalc.DeductRentMortgage = action.payload;
        },setDeductRatesGovRent:(state,action) => {
          state.ecalc.DeductRatesGovRent = action.payload;
        },setDeductMgtFee:(state,action) => {
          state.ecalc.DeductMgtFee = action.payload;
        },setDeductSalariesTax:(state,action) => {
          state.ecalc.DeductSalariesTax = action.payload;
        },setDeductMPF:(state,action) => {
          state.ecalc.DeductMPF = action.payload;
        },setDeductChildCareExp:(state,action) => {
          state.ecalc.DeductChildCareExp = action.payload;
        },setDeductSchoolFee:(state,action) => {
          state.ecalc.DeductSchoolFee = action.payload;
        },setDeductAvgMedicalExp:(state,action) => {
          state.ecalc.DeductAvgMedicalExp = action.payload;
        },setDeductMaintPaid:(state,action) => {
          state.ecalc.DeductMaintPaid = action.payload;
        },setDeductPersonalAllowance:(state,action) => {
          state.ecalc.DeductPersonalAllowance = action.payload;
        },
        setInit:(state,action) => {
          state.ecalc.Init = action.payload;
        },
        setInit2:(state,action) => {
          state.ecalc.Init2 = action.payload;
        },

    }
    })
    let prvstate = loadState();
    const store = configureStore({
      reducer: eCalcStateSlice.reducer,
      preloadedState:prvstate
    })    
    store.subscribe(throttle(() => {
        saveState({
            config:{lang:store.getState().config.lang},
            ecalc: store.getState().ecalc
        });
      },1000));    

      const {setState,setLang,setSubTotal1,setDeductGrandTotal,setDeductTotal,setSubTotal2,
        setDeductRentMortgage,
        setDeductRatesGovRent,
        setDeductMgtFee,
        setDeductSalariesTax,
        setDeductMPF,  
        setDeductChildCareExp,
        setDeductSchoolFee,  
        setDeductAvgMedicalExp,  
        setDeductMaintPaid,
        setDeductPersonalAllowance,
        setInit,
        setInit2,} = eCalcStateSlice.actions;
  export {loadState,saveState,store, eCalcStateSlice, 
    setState, setLang,setSubTotal1,setDeductGrandTotal,setDeductTotal,setSubTotal2,
    setDeductRentMortgage,
    setDeductRatesGovRent,
    setDeductMgtFee,
    setDeductSalariesTax,
    setDeductMPF,  
    setDeductChildCareExp,
    setDeductSchoolFee,  
    setDeductAvgMedicalExp,  
    setDeductMaintPaid,
    setDeductPersonalAllowance,
    setInit,
    setInit2,
};