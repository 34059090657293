import * as React from 'react';
import {Stack,Typography, Grid,  Box, Button,IconButton, Dialog, DialogActions,DialogContent,DialogContentText,DialogTitle,  
        TextField   } from '@mui/material';
import { ECalApplicationFormBodyHeadDesktop,SubtotalAssetAccordionDesktop,
          PatientAssetAccordionHeaderDesktop,PatientAssetAccordionDesktop,FamilyAssetAccordionDesktop,
        convFloat, updateHouseholdincomeItem,removeHouseholdincomeItem} from './ECalApplicationFormCommon.js';
import { useDispatch,useSelector } from 'react-redux'
import { setState,
  setSubTotal2,  
  setDeductRentMortgage,
  setDeductRatesGovRent,
  setDeductMgtFee,
  setDeductSalariesTax,
  setDeductMPF,  
  setDeductChildCareExp,
  setDeductSchoolFee,  
  setDeductAvgMedicalExp,  
  setDeductMaintPaid,
  setDeductPersonalAllowance,} from '../store/eCalcStore.js';
import moment from 'moment';
import {useEffectOnce} from '../common/common-lib.js';
import axios from 'axios';
const APIHost = window.APIHost;

function ECalApplicationFormBodyAsset(props){
  
    let householdAssetTotal = props.ecalcState.householdAssetTotal;
    let householdIncomeTotal = props.ecalcState.householdIncomeTotal;
    let householdMemberCount = props.ecalcState.householdMemberCount;
    
    let householdIncomeAssets  = props.ecalcState.householdIncomeAssets;
    let EstimatedItemCost = props.ecalcState.EstimatedItemCost;
    let isDrug = props.ecalcState.isDrug;
    let DeductTotal = props.ecalcState.DeductTotal;
    let DeductPersonalAllowance  = props.ecalcState.DeductPersonalAllowance;
    const recurrentApp = props.ecalcState.recurrentApp;
    const  _CCFMD = props.ecalcState._CCFMD;

    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [allReset,setAllReset] = React.useState(false);

    // const lang = useSelector((state)=> state.config.lang);
//     const [reviewResult,setReviewResult] =React.useState(props.i18n.t("tab5_eligible"));
// const [review_income,setReview_income] = React.useState(props.i18n.t("tab5_income_01_b_50"));
// const [review_asset,setReview_asset] = React.useState( props.i18n.t("tab5_asset_01_b_lmt"));
// const [reviewResultTip,setReviewResultTip] = React.useState(false);
    const handleClose2 = () => {
        setOpen2(false);
      };
    
    const handleOk2=()=>{
        setOpen2(false);    
        
        if(allReset){ props.onReset();}else{
          
          let _householdIncomeAssets = householdIncomeAssets.map((item) => {return { ...item, cash:0,saving:0,investment:0,insurance:0, property:0,carpark:0,land:0,other:0,subTotal2:0};});
        
          //console.log(householdIncomeAssets2);
          dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal:0,householdMemberCount,
            householdIncomeAssets:_householdIncomeAssets,DeductTotal,DeductPersonalAllowance,
          }));
          // props.calculateDeduct(()=>{
          //   setDiagTitle("");
          //   setDiagContent(props.i18n.t('msg_currency_out_of_range'));
          //   setOpen(true);   
          // });
          //dispatch(setSubTotal2(0));
           //               dispatch(setDeductRentMortgage(0));
            //              dispatch(setDeductRatesGovRent(0));
            //              dispatch(setDeductMgtFee(0));
            //              dispatch(setDeductSalariesTax(0));
            //              dispatch(setDeductMPF(0));
            //              dispatch(setDeductChildCareExp(0));
            //              dispatch(setDeductSchoolFee(0));
             //             dispatch(setDeductAvgMedicalExp(0));
             //             dispatch(setDeductMaintPaid(0));
             //             dispatch(setDeductPersonalAllowance(0));
        }
      }
    const handleClickOpen = (helpTipsTitleKey,helpTipsKey) => {
      setDiagTitle(props.i18n.t(helpTipsTitleKey));
      setDiagContent(props.i18n.t(helpTipsKey));
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const [diagTitle, setDiagTitle] = React.useState( props.i18n.t("tab3_note_tooltip_title") );
    const [diagContent, setDiagContent] = React.useState(props.i18n.t("tab3_note_tooltip_desc"));
    const relationStr =[
      { value:"Parent" , text: props.i18n.t("cboRelation_parent") } ,
      { value:"Child" , text:props.i18n.t("cboRelation_child")} ,
      { value:"Spouse" , text:props.i18n.t("cboRelation_spouse")} ,
      { value:"Sibling" , text:props.i18n.t("cboRelation_brosis")} ,
      { value:"Others" , text:props.i18n.t("cboRelation_other")} ,
    ]
    const GetPersonalAllowance= ()=>{
      //input: hhm_size//HouseholdMemberCount
      //output: result//0: Fail; 1:Success
      //assessment_date//2023-11-07
      //hhm_size
      // personal_allowance

      axios({
        method: 'post',
        url: APIHost+'/getPersonalAllowance',
        data: {
          "hhm_size":householdMemberCount,
          "appDtm":moment().format("YYYY-MM-DD HH:mm:ss"),
        },
       
      }).then((resp)=>{
        //console.log(resp.data);
        dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets,householdMemberCount,
                DeductTotal,DeductPersonalAllowance:convFloat(resp.data.result.personal_allowance)}));
             
      }).catch((err)=>{
        console.log(err);
        setDiagTitle("Connection error");            
        setDiagContent(err.toString());
        setOpen(true);
      })
    }
    React.useEffect(()=>{
      props.calculateDeduct(()=>{
        setDiagTitle("");
        setDiagContent(props.i18n.t('msg_currency_out_of_range'));
        setOpen(true);   
      });
    },[DeductPersonalAllowance,]);
    useEffectOnce(()=>{  
      GetPersonalAllowance();
     
    });
  return (
    <>
    <Box sx={{ p:2,m:2,border:1, borderRadius:1 }} >
    <ECalApplicationFormBodyHeadDesktop  isDrug={isDrug} onChange={props.onChange}  ecalcState={props.ecalcState} stepVal={props.stepVal}  enabledStep={props.enabledStep} i18n={props.i18n}/>
    <Stack sx={{ ml:1 }}>
      <Typography variant="h5" > <br/></Typography>      
      <Typography variant="h5"  color="grey.700" > {props.i18n.t("tab3_household_count")} {householdMemberCount}</Typography>
    </Stack> 
    <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
    <Button  variant="text" startIcon={<img width="18rem" height="18rem" src="/images/preview.gif"/>} sx={{fontSize: 22,color: 'teal'}} onClick={()=>{                       
                        props.printPreviewPre({});    
                    }} >  {props.i18n.t("btnPreview")}</Button>
        
        <Button  variant="text" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688'}} onClick={()=>{
            /*confirm clear popup, initial */ 
            setDiagContent(props.i18n.t("msg_confirm_clear_all"));
            setDiagTitle(props.i18n.t("tab3_header"));
            setAllReset(true);
            setOpen2(true);
            }}>{props.i18n.t("btnClearAll")}</Button>
    </Stack> 
    <Typography variant="h5" sx={{pb:1}}> {props.i18n.t("tab3_note")} <IconButton onClick={()=>{handleClickOpen("tab3_note_tooltip_title","tab3_note_tooltip_desc")}} ><img src="images/question_mark.png"/></IconButton></Typography>
   
    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start"  >
    <PatientAssetAccordionHeaderDesktop isDrug={isDrug} householdincomeArry={householdIncomeAssets}  onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{handleClickOpen(helpTipsTitleKey,helpTipsKey);}} 
            i18n={props.i18n}/>
<Box sx={{overflow: "hidden",overflowX: "scroll",}}>
            <Stack direction="row"   justifyContent="flex-start" alignItems="flex-start" > 
    <PatientAssetAccordionDesktop isDrug={isDrug} FamilyIdx={0} householdincomeArry={householdIncomeAssets}  onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{
      //console.log(helpTipsKey);
      handleClickOpen(helpTipsTitleKey,helpTipsKey);}} onChange={(ev)=>{
        //console.log(ev);
        let _householdIncomeAssets = updateHouseholdincomeItem(householdIncomeAssets,0,ev.item,convFloat(ev.val));      
        let _subtotal =  _householdIncomeAssets[0].cash+
                          _householdIncomeAssets[0].saving+
                          _householdIncomeAssets[0].investment+
                          _householdIncomeAssets[0].insurance+
                          _householdIncomeAssets[0].property+
                          _householdIncomeAssets[0].carpark+
                          _householdIncomeAssets[0].land+
                          _householdIncomeAssets[0].other;                          
        _householdIncomeAssets = updateHouseholdincomeItem(_householdIncomeAssets,0,"subTotal2",_subtotal);     
        householdAssetTotal=0;     
        _householdIncomeAssets.forEach((record)=>{              
        householdAssetTotal +=record.subTotal2;
       });
       //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
       dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
    }} i18n={props.i18n}/>
    {householdIncomeAssets.map((income)=>{
        //console.log(income);
        if(income.id>0)
          return (<FamilyAssetAccordionDesktop key={"faa"+income.id} relationStr={relationStr} FamilyIdx={income.id} householdincomeArry={householdIncomeAssets} isDrug={isDrug} onRemove={(id)=>{
            let _householdIncomeAssets = removeHouseholdincomeItem(householdIncomeAssets,id);
            householdAssetTotal=0;
            _householdIncomeAssets.forEach((record)=>{                        
              householdAssetTotal +=record.subTotal2;
            });
            
            dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
          }} onChange={(ev)=>{
              let _householdIncomeAssets = updateHouseholdincomeItem(householdIncomeAssets,income.id,ev.item,(ev.item==='isOver65'||ev.item==='patient_rel')?ev.val:convFloat(ev.val));      
              let _subtotal = _householdIncomeAssets[income.id].cash+
                              _householdIncomeAssets[income.id].saving+
                              _householdIncomeAssets[income.id].investment+
                              _householdIncomeAssets[income.id].insurance+
                              _householdIncomeAssets[income.id].property+
                              _householdIncomeAssets[income.id].carpark+
                              _householdIncomeAssets[income.id].land+
                              _householdIncomeAssets[income.id].other;
                                    
              _householdIncomeAssets = updateHouseholdincomeItem(_householdIncomeAssets,income.id,"subTotal2",_subtotal);
              
              householdAssetTotal=0;     
              _householdIncomeAssets.forEach((record)=>{              
                householdAssetTotal +=record.subTotal2;
            });
            //console.log({householdIncomeTotal,householdAssetTotal,householdIncomeAssets:householdIncomeAssets2,householdMemberCount,elderlyCount});
            dispatch(setState({isDrug,_CCFMD,recurrentApp,EstimatedItemCost,householdIncomeTotal,householdAssetTotal,householdIncomeAssets:_householdIncomeAssets,householdMemberCount,DeductTotal,DeductPersonalAllowance,}));
          }} onShowHelp={(helpTipsTitleKey,helpTipsKey)=>{
            //console.log(helpTipsKey);
            handleClickOpen(helpTipsTitleKey,helpTipsKey);}} i18n={props.i18n}/>)
      })}
      </Stack>
          </Box>
      <SubtotalAssetAccordionDesktop householdincomeArry={householdIncomeAssets} i18n={props.i18n}/>
      </Stack>
      <Typography variant="h6" > {props.i18n.t("input_integer_only")}</Typography>
        <Stack direction="row" alignItems="center"  justifyContent="flex-end"> 
          <Typography variant="h5" display="inline" sx={{textDecoration: 'underline',}}>{props.i18n.t("grand_total")}</Typography>     
          <TextField value={householdAssetTotal.toLocaleString('en-US',{style:"currency", currency:"USD",minimumFractionDigits: 0})} variant="outlined"  sx={{p:1,width:"16rem",input: {py:0.1,textAlign: "right",backgroundColor:'grey.200',fontSize:"1.5rem"}}}/>
        </Stack>
    
        <Grid container spacing={2} >
        <Grid item xs={4} >
          <Button variant="outlined" onClick={()=>{props.onChange("income")}} sx={{fontSize: 22,backgroundColor:'grey.200'}}><img src="/images/arrow_left_btn.gif" width="14px" height="14px"/>{props.i18n.t("btnPrevious")}</Button>
        </Grid>
        <Grid item xs={4} > <Stack direction="row" justifyContent="center" > <Button  variant="outlined" startIcon={<img src="/images/reset_btn.gif"/>} sx={{fontSize: 22,color: '#009688',backgroundColor:'grey.200'}} onClick={()=>{
            /*confirm clear popup, setvalue to 0*/             
             setDiagContent( props.i18n.t("msg_confirm_clear_page").replace("{0}",props.i18n.t("tab3_header")));
             setDiagTitle(props.i18n.t("tab3_header"));
             setAllReset(false);
             setOpen2(true);
            }}>{props.i18n.t("btnClear")}</Button></Stack> </Grid>
        <Grid item xs={4} ><Stack  direction="row"  justifyContent="flex-end"><Button variant="outlined" onClick={()=>{isDrug=='true'?props.onChange("monthlyDeduct"):props.onChange("result");props.onNext();}} sx={{fontSize: 22,backgroundColor:'grey.200'}}>{props.i18n.t("btnNext")}<img src="/images/arrow_btn.gif" width="14px" height="14px"/></Button></Stack></Grid>
      </Grid>    
    </Box>
    
     <Dialog
         open={open}
         onClose={handleClose}
         fullwidth
       >
         <DialogTitle >
           {diagTitle}
         </DialogTitle>
         <DialogContent>
           <DialogContentText >
             <div dangerouslySetInnerHTML={{__html:diagContent}}/>
           </DialogContentText>
         </DialogContent>
         <DialogActions>          
           <Button onClick={handleClose} autoFocus>
           {props.i18n.t("tooltip_close")}
           </Button>
         </DialogActions>
       </Dialog>
       <Dialog
          open={open2}
          onClose={handleClose2}
          fullwidth
        >
          <DialogTitle >
            {diagTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText >
              {diagContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>          
            <Button onClick={handleClose2} autoFocus>
              Cancel
            </Button>
            <Button onClick={handleOk2} autoFocus>
              ok
            </Button>
          </DialogActions>
        </Dialog>
     </>)
  
  }
  
  export {ECalApplicationFormBodyAsset as ECalApplicationFormBodyAssetDesktop}